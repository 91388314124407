import React, { Component } from 'react';

class GoogleSquareAd extends Component {
    state = {}

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8916098346441244"
                data-ad-slot="9245068461"
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        );
    }
}

export default GoogleSquareAd;