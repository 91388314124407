import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import PointsPlusCalculatorDesktop from './desktop/points-plus-calculator-desktop';
import PointsPlusCalculatorMobile from './mobile/points-plus-calculator-mobile';

class PointsPlusCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                {isMobile ?
                    <PointsPlusCalculatorMobile />
                    :
                    <PointsPlusCalculatorDesktop />
                }
            </React.Fragment>
        );
    }
}

export default PointsPlusCalculator;