import React, { Component } from 'react';
import { browserName } from 'react-device-detect';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../../styles/calculator.css';

class SpCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browser: null
        };

        this.calcSmartPoints = this.calcSmartPoints.bind(this)
        this.calcReset = this.calcReset.bind(this)
    }

    componentDidMount() {
        this.setState({ browser: browserName })
    }

    calcSmartPoints(e) {
        e.preventDefault()

        let calories = parseFloat(e.target.calories.value)
        let fat = parseFloat(e.target.fat.value)
        let sugar = parseFloat(e.target.sugar.value)
        let protein = parseFloat(e.target.protein.value)

        if (isNaN(calories)) { calories = 0 }
        if (isNaN(fat)) { fat = 0 }
        if (isNaN(sugar)) { sugar = 0 }
        if (isNaN(protein)) { protein = 0 }

        let smartPoints = Math.round((calories * 0.0305) + (fat * .275) + (sugar * .12) - (protein * .098));

        if (isNaN(smartPoints)) { smartPoints = 0 }

        if (this.props.windowWidth < 800) {
            e.target.results.value = smartPoints + " Smart Points"
        }
        else {
            e.target.results.value = "Your Smart Point Value: " + smartPoints + " pts"
        }
    }

    calcReset() {
        let inputs = ['calories', 'fat', 'sugar', 'protein', 'results']

        for (let i in inputs) {
            document.getElementById(inputs[i]).value = ""
        }
    }

    setFormStyle() {
        let windowWidth = this.props.windowWidth

        if (windowWidth >= 1024) {
            return { width: '75%', margin: 'auto', marginTop: '10px' }
        }
        else {
            return { width: '95%', margin: 'auto', marginTop: '10px' }
        }
    }

    render() {
        const { browser } = this.state

        return (
            <Form onSubmit={this.calcSmartPoints} style={this.setFormStyle()} autoComplete="off">
                {browser ?
                    null
                    :
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ position: 'relative', fontSize: '.875rem' }}><span style={{ fontWeight: 'bold', color: '#dc3545' }}>Warning</span>: For security reasons, if you are using Internet Explorer you must upgrade to Edge to use this calculator.</p>
                    </div>
                }
                <h1 className={this.props.windowWidth < 500 ? "calc-header-mobile" : "calc-header"}>{this.props.title}</h1>
                <div className="calc-body">
                    <Col sm="6" style={{ margin: 'auto' }}>
                        <Form.Label className="calc-label">CALORIES</Form.Label>
                        <Form.Control
                            id="calories"
                            name="calories"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">SATURATED FAT (g)</Form.Label>
                        <Form.Control
                            id="fat"
                            name="fat"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">SUGAR (g)</Form.Label>
                        <Form.Control
                            id="sugar"
                            name="sugar"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">PROTEIN (g)</Form.Label>
                        <Form.Control
                            id="protein"
                            name="protein"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Button onClick={this.calcReset} type="button" className="calc-button" variant="danger">Reset</Button>
                        <Button type="submit" className="calc-button" style={{ float: 'right' }}>Submit</Button>
                    </Col>
                    <div className="calc-results">
                        <Form.Control
                            id="results"
                            name="results"
                            type="text"
                            readOnly
                        >
                        </Form.Control>
                    </div>
                </div>
            </Form >
        );
    }
}

export default SpCalculator;