import React, { Component } from 'react';
import { isMobile, browserName } from 'react-device-detect';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

class ApCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browser: null
        };

        this.calcAllowPoints = this.calcAllowPoints.bind(this)
        this.calcReset = this.calcReset.bind(this)
    }

    componentDidMount() {
        this.setState({ browser: browserName })
    }

    calcAllowPoints(e) {
        e.preventDefault();

        let heightPoints
        let height = e.target.height.value

        if (e.target.heightType.value === 'in') {
            if (height <= 60) { heightPoints = 0 }
            else if (height <= 70) { heightPoints = 1 }
            else { heightPoints = 2 }
        }
        else {
            if (height < 155) { heightPoints = 0 }
            else if (height < 178) { heightPoints = 1 }
            else { heightPoints = 2 }
        }

        let weightPoints
        let weight = e.target.weight.value

        if (e.target.weightType.value === 'lbs') {
            weightPoints = Math.round((parseInt(weight) / 10))
        }
        else {
            weightPoints = Math.round(((weight * 2.205) / 10))
        }

        if (isNaN(heightPoints)) { heightPoints = 0 }
        if (isNaN(weightPoints)) { weightPoints = 0 }

        let genderPoints = parseInt(e.target.gender.value)
        let agePoints = parseInt(e.target.age.value)
        let activityPoints = parseInt(e.target.activity.value)

        let dailyPoints = genderPoints + agePoints + heightPoints + weightPoints + activityPoints

        if (dailyPoints < 26) { dailyPoints = 26 }
        else if (dailyPoints > 71) { dailyPoints = 71 }

        if (isNaN(dailyPoints)) { dailyPoints = 0 }

        if (this.props.windowWidth < 800) {
            e.target.results.value = dailyPoints + " pts"
        }
        else {
            e.target.results.value = "Daily Allowance:  " + dailyPoints + ' pts     (plus 42 weekly pts)'
        }


    }

    calcReset() {
        let inputs = ['height', 'weight', 'results']

        for (let i in inputs) {
            document.getElementById(inputs[i]).value = ""
        }

        document.getElementById('gender').value = "8"
        document.getElementById('age').value = "4"
        document.getElementById('heightType').value = "in"
        document.getElementById('weightType').value = "lbs"
        document.getElementById('activity').value = "0"
    }

    render() {
        const { browser } = this.state

        return (
            <Form onSubmit={this.calcAllowPoints} style={isMobile ? { width: '95%', margin: 'auto', marginTop: '10px' } : { width: '75%', margin: 'auto', marginTop: '10px' }} autoComplete="off">
                {!browser ?
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ position: 'relative', fontSize: '.875rem' }}><span style={{ fontWeight: 'bold', color: '#dc3545' }}>Warning</span>: For security reasons, if you are using Internet Explorer you must upgrade to Edge to use this calculator.</p>
                    </div>
                    : null}
                <h1 className={this.props.windowWidth < 500 ? "calc-header-mobile" : "calc-header"}>Weight Watchers Points Allowance Calculator</h1>
                <div className="calc-body">
                    <Col sm="6" style={{ margin: 'auto' }}>
                        <Form.Label className="calc-label">GENDER</Form.Label>
                        <Form.Control
                            id="gender"
                            name="gender"
                            as="select"
                            className="calc-input"
                        >
                            <option value="8">Male</option>
                            <option value="2">Female</option>
                            <option value="12">Nursing Female</option>
                        </Form.Control>
                        <Form.Label className="calc-label">AGE RANGE</Form.Label>
                        <Form.Control
                            id="age"
                            name="age"
                            as="select"
                            className="calc-input"
                        >
                            <option value="4">17 to 26</option>
                            <option value="3">27 to 37</option>
                            <option value="2">38 to 47</option>
                            <option value="1">48 and 57</option>
                            <option value="0">58 And Older</option>
                        </Form.Control>
                        <Form.Label className="calc-label">HEIGHT</Form.Label>
                        <Row>
                            <Col style={{ flex: 2, paddingRight: 0 }}>
                                <Form.Control
                                    id="height"
                                    name="height"
                                    type="text"
                                    className="calc-input"
                                >
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Control
                                    id="heightType"
                                    name="heightType"
                                    as="select"
                                    className="calc-input"
                                    defaultValue="in"
                                >
                                    <option value="in">inches</option>
                                    <option value="cm">cm</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Form.Label className="calc-label">WEIGHT</Form.Label>
                        <Row>
                            <Col style={{ flex: 2, paddingRight: 0 }}>
                                <Form.Control
                                    id="weight"
                                    name="weight"
                                    type="text"
                                    className="calc-input"
                                >
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Control
                                    id="weightType"
                                    name="weightType"
                                    as="select"
                                    className="calc-input"
                                >
                                    <option value="lbs">lbs</option>
                                    <option value="kg">kg</option>
                                </Form.Control>
                            </Col>
                        </Row>
                        <Form.Label className="calc-label">ACTIVITY LEVEL</Form.Label>
                        <Form.Control
                            id="activity"
                            name="activity"
                            as="select"
                            className="calc-input"
                        >
                            <option value="0">Sedentary/Inactive</option>
                            <option value="2">Light activity</option>
                            <option value="4">Moderate activity</option>
                            <option value="6">Heavy exercise</option>
                        </Form.Control>
                        <Button onClick={this.calcReset} type="button" className="calc-button" variant="danger">Reset</Button>
                        <Button type="submit" className="calc-button" style={{ float: 'right' }}>Submit</Button>
                    </Col>
                    <div className="calc-results">
                        <Form.Control
                            id="results"
                            name="results"
                            type="text"
                            readOnly
                        >
                        </Form.Control>
                    </div>
                </div>
            </Form>
        );
    }
}

export default ApCalculator;