import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import ZPFoodsDesktop from './desktop/zero-point-foods-desktop';
import ZPFoodsMobile from './mobile/zero-point-foods-mobile';

class ZeroPointFoodsCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <React.Fragment>
                {isMobile ?
                    <ZPFoodsMobile />
                    :
                    <ZPFoodsDesktop />
                }
            </React.Fragment>
        );
    }
}

export default ZeroPointFoodsCalculator;