import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Footer from './footer';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            windowWidth: window.outerWidth
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }
        }, 50);
    }

    render() {
        const { windowType } = this.state

        return (
            <React.Fragment>
                <div className={"container" + windowType} style={{ paddingTop: '2rem' }}>
                    <div className={"page-content" + windowType}>

                        <h2>About Us</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>Here at Watchers Point we are dedicated to providing you with innovative calculators and solutions for all of your Weight Watchers needs.</p>
                            <p>Whether you’re still participating in the PointPlus program, have transitioned into using SmartPoints, subscribe to a Freestyle program or ZeroPoint food plan, we have a calculator or tool to assist you.</p>
                            <p>You can calculate the SmartPoint value of foods or drinks with our <a href="/smart-point-calculator">Weight Watchers points calculator</a>. Determine the point plus value of foods or drinks with our <a href="/points-plus-calculator">Points Plus calculator</a>. Figure out your daily smart point allowance with our <a href="/allowance-calculator">allowance points calculator</a>, or quickly and easily filter through the green, blue, and purple ZeroPoint food lists with our <a href="/zero-point-foods">zero point foods</a> tool.</p>
                            <p>We’re always looking to expand the helpful information on our site and build new calculators or tools for our users. So, if you have an idea that you’d like to see implemented, don’t hesitate to reach out to us via our <a href="/contact-us">Contact page</a>.</p>
                            <p>Otherwise, we hope this site can assist you and make tracking your Weight Watchers points less of a chore!</p>
                        </div>
                    </div>
                </div>
                <Footer />
                <Helmet>
                    <title>About Us | Watchers Point</title>
                    <meta name='description' content="Here at Watchers Point we are dedicated to providing you with innovative calculators and solutions for all of your Weight Watchers needs." />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default AboutUs;