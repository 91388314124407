import React, { Component } from 'react';

class Footer extends Component {
    state = {}
    render() {
        return (
            <div style={{ width: '100%', boxShadow: 'inset 0 9px 12px -9px rgba(0,0,0,0.08)', background: '#fafafa' }}>
                <footer style={{ padding: '4rem 0 2rem 0', textAlign: 'center' }}>
                    <div className="footer-text">
                        <h2>Watchers  Point</h2>
                    </div>
                    <div className="footer-social-icon">
                        <a href="/"><i className="active fa fa-facebook" aria-hidden="true"></i> </a>
                        <a href="/"><i className="fa fa-twitter" aria-hidden="true"></i> </a>
                        <a href="/"><i className="fa fa-instagram" aria-hidden="true"></i> </a>
                        <a href="/"><i className="fa fa-google-plus" aria-hidden="true"></i> </a>
                    </div>
                    <div className="footer-menu">
                        <nav>
                            <ul style={{ margin: 0, padding: 0 }}>
                                <li><a href="/">Home</a></li>
                                <li><a href="/weight-watchers-smart-points-calculator">Smart Point Calculator</a></li>
                                <li><a href="/points-plus-calculator">Points Plus Calculator</a></li>
                                <li><a href="/allowance-calculator">Allowance Calculator</a></li>
                                <li><a href="/weight-watchers-zero-point-foods">Zero Point Foods</a></li>
                                <li><a href="/about-us">About</a></li>
                                <li><a href="/contact-us">Contact</a></li>
                            </ul>
                        </nav>
                        <div style={{ width: '65rem', maxWidth: '92.5%', margin: '2rem auto', textAlign: 'justify' }}>
                            <p style={{ color: '#212529' }}><span style={{ color: "red" }}>Disclaimer</span>: Watchers Point has no affiliation with Weight Watchers® and has no intent to present Weight Watchers® products or Weight Watchers® intellectual property as its own. The purpose of the calculators and solutions available on this site is to provide convenient methods for estimating point values based on research using publicly accessible information from Wikipedia and the CDC.</p>
                        </div>
                        <nav>
                            <ul style={{ margin: 0, padding: 0, marginTop: '2rem' }}>
                                <li><a style={{ color: '#212529' }} href="/privacy-policy-and-terms-of-service">Privacy Policy & TOS</a></li>
                            </ul>
                        </nav>
                        <div className="copyright-text" style={{ marginTop: '.5rem' }}>
                            <p>Copyright © 2021 Watchers Point</p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;