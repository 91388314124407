import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import MainMenu from '../../images/main-menu.png';
import DailyFoodTracker from '../../images/daily-food-tracker.png';
import DailyWeightLogger from '../../images/daily-weight-logger.png';
import SmartpointCalculator from '../../images/smartpoint-calculator.png';
import FoodPointLists from '../../images/food-point-lists.png'
import ActivityPointTracker from '../../images/activity-points-tracker.png'
import PersonalPointList from '../../images/personal-points-lists.png'
import GreenList from '../../images/green-list.png';
import BlueList from '../../images/blue-list.png';
import PurpleList from '../../images/purple-list.png';

const appSliderText = ["Scan food and drink barcodes!", "Track daily & weekly point usage!", "Calculate SmartPoints of foods & drinks!", "Monitor daily weight changes!", "Create your own food list for easy tracking!", "Quickly navigate and filter zero point foods!"]
const appSliderPhotos = [MainMenu, DailyFoodTracker, SmartpointCalculator, DailyWeightLogger, FoodPointLists, ActivityPointTracker, PersonalPointList, GreenList, BlueList, PurpleList]

class BannerMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { windowWidth } = this.props

        return (
            <div className="welcome-area" style={{ overflow: 'hidden' }}>
                <div style={{ height: '100px' }}>
                    <div className="welcome-heading-mobile">
                        <h2 className="w-h1-pink" style={windowWidth <= 320 ? { marginTop: '-.5rem', fontSize: '1.1rem', textAlign: 'left', fontWeight: 'bold' } : { marginTop: '-.5rem', fontSize: '1.3rem', textAlign: 'left', fontWeight: 'bold' }}>
                            DOWNLOAD OUR APP!
                        </h2>
                        <h3 className="bounce" style={{ overflow: 'hidden' }}>WatchPoint</h3>
                        <Carousel controls={false} indicators={false} style={{ position: 'absolute', top: '4rem', width: '60%', textAlign: 'center' }}>
                            {appSliderText.map((text, i) => (
                                <Carousel.Item key={i} style={windowWidth <= 320 ? { color: 'white', fontSize: '1.1rem', marginLeft: '-.75rem' } : { color: 'white', fontSize: '1.25rem' }}>
                                    {text}
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <div style={{ position: 'absolute', top: '8.5rem' }}>
                            <div className="welcome-app-button-mobile" style={{ width: '20%', textAlign: 'center' }}>
                                <a id="download-now" rel="noreferrer" target="_blank" href="https://apps.apple.com/us/app/iwatchpoints/id6443529573" className="bkgd-color-change" style={{ fontSize: '1rem' }}>Download (iPhone)</a>
                            </div>
                            <div className="welcome-app-button-mobile" style={{ width: '20%', textAlign: 'center' }}>
                                <a id="download-now" rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=io.ionic.watcherspoint&hl=en-US&ah=fwv4wR27Hpc81mA9YrDXhWh1DUo" className="bkgd-color-change" style={{ marginTop: '.5rem', fontSize: '1rem' }}>Download (Android)</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: '9.5rem', right: '1rem', width: '6.5rem' }}>
                    <Carousel style={{ background: 'linear-gradient(0deg, #4dabff 0%, #62ddff 100%)' }} controls={false} indicators={false} interval={2000}>
                        {appSliderPhotos.map((photo, i) => (
                            <Carousel.Item key={i}>
                                <Image style={{ width: '100%', borderRadius: '.25rem' }} src={photo} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default BannerMobile;