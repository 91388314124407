import React, { Component } from 'react';
import { isMobile, browserName } from 'react-device-detect';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const showAll = new RegExp(".*", "i")

const green = {
    'FRUITS': ['Apples', 'Applesauce, unsweetened', 'Apricots, fresh', 'Bananas', 'Blackberries', 'Blueberries', 'Cantaloupe', 'Cherries', 'Clementines', 'Cranberries, fresh', 'Dragon fruit', 'Figs, fresh', 'Frozen mixed berries, unsweetened', 'Fruit cocktail, unsweetened', 'Fruit salad, unsweetened', 'Grapefruit', 'Grapes', 'Guava', 'Honeydew', 'Kiwi', 'Kumquats', 'Lemons', 'Limes', 'Mangoes', 'Meyer lemons', 'Nectarines', 'Oranges', 'Papayas', 'Peaches', 'Pears', 'Persimmons', 'Pineapples', 'Plums', 'Pomegranates', 'Pomelo', 'Raspberries', 'Star fruit', 'Strawberries', 'Tangerines', 'Watermelon'],
    'VEGETABLES (NON-STARCHY)': ['Acorn squash', 'Artichoke hearts, no oil', 'Artichokes', 'Arugula', 'Asparagus', 'Baby corn', 'Bamboo shoots', 'Basil', 'Beet greens', 'Beets', 'Bok choy', 'Broccoli', 'Broccoli rabe', 'Broccoli slaw', 'Brussels sprouts', 'Butter / Bibb lettuce', 'Butternut squash', 'Cabbage', 'Canned pimientos', 'Carrots', 'Cauliflower', 'Cauliflower rice', 'Celery', 'Chives', 'Cilantro', 'Coleslaw mix', 'Collard greens', 'Cucumber', 'Eggplant', 'Endive', 'Escarole', 'Fennel', 'Frozen stir-fry vegetables, no sauce', 'Garlic', 'Ginger', 'Green leaf lettuce', 'Hearts of palm', 'Iceberg lettuce', 'Jicama', 'Kale', 'Kohlrabi', 'Leeks', 'Mint', 'Mixed greens', 'Mushrooms', 'Mustard greens', 'Napa cabbage', 'Nori(seaweed)', 'Oak leaf lettuce', 'Okra', 'Onions', 'Oregano', 'Parsley', 'Pea shoots', 'Peppers', 'Pickles, unsweetened', 'Pico de gallo', 'Pumpkin', 'Pumpkin puree', 'Radishes', 'Red leaf lettuce', 'Romaine lettuce', 'Rosemary', 'Rutabaga', 'Salsa, fat-free', 'Sauerkraut', 'Scallions', 'Shallots', 'Spaghetti squash', 'Spinach', 'String beans', 'Summer squash', 'Swiss chard', 'Tarragon', 'Thyme', 'Tomatillos', 'Tomato puree, canned', 'Tomato sauce, canned', 'Tomatoes', 'Turnips', 'Water chestnuts', 'Wax beans', 'Zucchini']
}

const blue = {
    'BEANS & LEGUMES': ['Adzuki beans', 'Alfalfa sprouts', 'Bean sprouts', 'Black beans', 'Black-eyed peas', 'Cannellini beans', 'Chickpeas', 'Edamame', 'Fava beans', 'Great', 'Northern beans', 'Kidney beans', 'Lentils', 'Lima beans', 'Lupini beans', 'Navy beans', 'Pinto beans', 'Refried beans, canned, fat-free', 'Soy beans'],
    'CHICKEN & TURKEY BREAST': ['Ground chicken breast', 'Ground turkey, 98% fat-free', 'Ground turkey breast', 'Skinless chicken breast', 'Skinless turkey breast'],
    'EGGS': ['Egg substitute', 'Egg whites', 'Egg yolks', 'Eggs'],
    'FISH/SHELLFISH': ['Abalone', 'Alaskan king crab', 'Anchovies, in water', 'Arctic char', 'Bluefish', 'Branzino', 'Butterfish', 'Canned tuna, in water', 'Carp', 'Catfish', 'Caviar', 'Clams', 'Cod', 'Crabmeat, lump', 'Crayfish', 'Cuttlefish', 'Dungeness crab', 'Eel', 'Fish roe', 'Flounder', 'Grouper', 'Haddock', 'Halibut', 'Herring', 'Lobster', 'Mahi mahi', 'Monkfish', 'Mussels', 'Octopus', 'Orange roughy', 'Oysters', 'Perch', 'Pike', 'Pollock', 'Pompano', 'Salmon', 'Sardines, canned in water or sauce', 'Sashimi', 'Scallops', 'Sea bass', 'Sea cucumber', 'Sea urchin', 'Shrimp', 'Smelt', 'Smoked haddock', 'Smoked salmon', 'Smoked sturgeon', 'Smoked trout', 'Smoked whitefish', 'Snails', 'Snapper', 'Sole', 'Squid', 'Steelhead trout', 'Striped bass', 'Sturgeon', 'Swordfish', 'Tilapia', 'Trout', 'Tuna', 'Turbot', 'Wahoo', 'Whitefish'],
    'FRUITS': ['Apples', 'Applesauce, unsweetened', 'Apricots, fresh', 'Bananas', 'Blackberries', 'Blueberries', 'Cantaloupe', 'Cherries', 'Clementines', 'Cranberries, fresh', 'Dragon fruit', 'Figs, fresh', 'Frozen mixed berries, unsweetened', 'Fruit cocktail, unsweetened', 'Fruit salad, unsweetened', 'Grapefruit', 'Grapes', 'Guava', 'Honeydew', 'Kiwi', 'Kumquats', 'Lemons', 'Limes', 'Mangoes', 'Meyer lemons', 'Nectarines', 'Oranges', 'Papayas', 'Peaches', 'Pears', 'Persimmons', 'Pineapples', 'Plums', 'Pomegranates', 'Pomelo', 'Raspberries', 'Star fruit', 'Strawberries', 'Tangerines', 'Watermelon'],
    'NONFAT YOGURT & SOY YOGURT': ['Greek yogurt, plain, nonfat', 'Plain yogurt, nonfat', 'Quark, plain, up to 1% fat', 'Soy yogurt, plain'],
    'TOFU & TEMPEH': ['Firm tofu', 'Silken tofu', 'Smoked tofu', 'Soft tofu', 'Tempeh'],
    'VEGETABLES (STARCHY)': ['Canned corn', 'Corn', 'Green peas', 'Parsnips', 'Peas', 'Split peas', 'Succotash'],
    'VEGETABLES (NON-STARCHY)': ['Acorn squash', 'Artichoke hearts, no oil', 'Artichokes', 'Arugula', 'Asparagus', 'Baby corn', 'Bamboo shoots', 'Basil', 'Beet greens', 'Beets', 'Bok choy', 'Broccoli', 'Broccoli rabe', 'Broccoli slaw', 'Brussels sprouts', 'Butter / Bibb lettuce', 'Butternut squash', 'Cabbage', 'Canned pimientos', 'Carrots', 'Cauliflower', 'Cauliflower rice', 'Celery', 'Chives', 'Cilantro', 'Coleslaw mix', 'Collard greens', 'Cucumber', 'Eggplant', 'Endive', 'Escarole', 'Fennel', 'Frozen stir-fry vegetables, no sauce', 'Garlic', 'Ginger', 'Green leaf lettuce', 'Hearts of palm', 'Iceberg lettuce', 'Jicama', 'Kale', 'Kohlrabi', 'Leeks', 'Mint', 'Mixed greens', 'Mushrooms', 'Mustard greens', 'Napa cabbage', 'Nori (seaweed)', 'Oak leaf lettuce', 'Okra', 'Onions', 'Oregano', 'Parsley', 'Pea shoots', 'Peppers', 'Pickles, unsweetened', 'Pico de gallo', 'Pumpkin', 'Pumpkin puree', 'Radishes', 'Red leaf lettuce', 'Romaine lettuce', 'Rosemary', 'Rutabaga', 'Salsa, fat-free', 'Sauerkraut', 'Scallions', 'Shallots', 'Spaghetti squash', 'Spinach', 'String beans', 'Summer squash', 'Swiss chard', 'Tarragon', 'Thyme', 'Tomatillos', 'Tomato puree, canned', 'Tomato sauce, canned', 'Tomatoes', 'Turnips', 'Water chestnuts', 'Wax beans', 'Zucchini']
}


const purple = {
    'BEANS & LEGUMES': ['Adzuki beans', 'Alfalfa sprouts', 'Bean sprouts', 'Black beans', 'Black-eyed peas', 'Cannellini beans', 'Chickpeas', 'Edamame', 'Fava beans', 'Great', 'Northern beans', 'Hominy', 'Kidney beans', 'Lentils', 'Lima beans', 'Lupini beans', 'Navy beans', 'Pinto beans', 'Refried beans, canned, fat-free', 'Soy beans'],
    'CHICKEN & TURKEY BREAST': ['Ground chicken breast', 'Ground turkey, 98% fat-free', 'Ground turkey breast', 'Skinless chicken breast', 'Skinless turkey breast'],
    'DAIRY & DAIRY ALTERNATIVES': ['Cottage cheese, plain, nonfat', 'Greek yogurt, plain, nonfat', 'Plain yogurt, nonfat', 'Quark, plain, up to 1% fat', 'Soy yogurt, plain'],
    'EGGS': ['Egg substitute', 'Egg whites', 'Egg yolks', 'Eggs'],
    'FISH/SHELLFISH': ['Abalone', 'Alaskan king crab', 'Anchovies', 'Arctic char', 'Bluefish', 'Branzino', 'Butterfish', 'Canned tuna, in water', 'Carp', 'Catfish', 'Caviar', 'Clams', 'Cod', 'Crabmeat, lump', 'Crayfish', 'Cuttlefish', 'Dungeness crab', 'Eel', 'Fish roe', 'Flounder', 'Grouper', 'Haddock', 'Halibut', 'Herring', 'Lobster', 'Mahi mahi', 'Monkfish', 'Mussels', 'Octopus', 'Orange roughy', 'Oysters', 'Perch', 'Pike', 'Pollock', 'Pompano', 'Salmon', 'Sardines, canned in water or sauce', 'Sashimi', 'Scallops', 'Sea bass', 'Sea cucumber', 'Sea urchin', 'Shrimp', 'Smelt', 'Smoked haddock', 'Smoked salmon', 'Smoked sturgeon', 'Smoked trout', 'Smoked whitefish', 'Snails', 'Snapper', 'Sole', 'Squid', 'Steelhead trout', 'Striped bass', 'Sturgeon', 'Swordfish', 'Tilapia', 'Trout', 'Tuna', 'Turbot', 'Wahoo', 'Whitefish'],
    'FRUITS': ['Apples', 'Applesauce, unsweetened', 'Apricots, fresh', 'Bananas', 'Blackberries', 'Blueberries', 'Cantaloupe', 'Cherries', 'Clementines', 'Cranberries, fresh', 'Dragon fruit', 'Figs, fresh', 'Frozen mixed berries, unsweetened', 'Fruit cocktail, unsweetened', 'Fruit salad, unsweetened', 'Grapefruit', 'Grapes', 'Guava', 'Honeydew', 'Kiwi', 'Kumquats', 'Lemons', 'Limes', 'Mangoes', 'Meyer lemons', 'Nectarines', 'Oranges', 'Papayas', 'Peaches', 'Pears', 'Persimmons', 'Pineapples', 'Plums', 'Pomegranates', 'Pomelo', 'Raspberries', 'Star fruit', 'Strawberries', 'Tangerines', 'Watermelon'],
    'POTATOES & SWEET POTATOES': ['Baby potatoes', 'Baked potatoes', 'Baked sweet potatoes', 'Canned sweet potato, unsweetened', 'Cassava', 'Fingerling potatoes', 'Frozen potatoes, prepared without fat', 'Idaho potatoes', 'Japanese sweet potato', 'Mashed potatoes, plain', 'Mashed sweet potatoes, plain', 'New potatoes', 'Oven-roasted potato wedges, without oil', 'Oven-roasted sweet potato fries, without oil', 'Purple potatoes', 'Red potatoes', 'Roasted potatoes, without oil', 'Roasted sweet potatoes, without oil', 'Russet potatoes', 'Sweet potatoes', 'Taro', 'White potatoes', 'Yams', 'Yellow potatoes', 'Yucca', 'Yukon gold potatoes'],
    'TOFU & TEMPEH': ['Firm tofu', 'Silken tofu', 'Smoked tofu', 'Soft tofu', 'Tempeh'],
    'VEGETABLES (STARCHY)': ['Canned corn', 'Corn', 'Green peas', 'Parsnips', 'Peas', 'Split peas', 'Succotash'],
    'VEGETABLES (NON-STARCHY)': ['Acorn squash', 'Artichoke hearts, no oil', 'Artichokes', 'Arugula', 'Asparagus', 'Baby corn', 'Bamboo shoots', 'Basil', 'Beet greens', 'Beets', 'Bok choy', 'Broccoli', 'Broccoli rabe', 'Broccoli slaw', 'Brussels sprouts', 'Butter / Bibb lettuce', 'Butternut squash', 'Cabbage', 'Canned pimientos', 'Carrots', 'Cauliflower', 'Cauliflower rice', 'Celery', 'Chives', 'Cilantro', 'Coleslaw mix', 'Collard greens', 'Cucumber', 'Eggplant', 'Endive', 'Escarole', 'Fennel', 'Frozen stir-fry vegetables, no sauce', 'Garlic', 'Ginger', 'Green leaf lettuce', 'Hearts of palm', 'Iceberg lettuce', 'Jicama', 'Kale', 'Kohlrabi', 'Leeks', 'Mint', 'Mixed greens', 'Mushrooms', 'Mustard greens', 'Napa cabbage', 'Nori (seaweed)', 'Oak leaf lettuce', 'Okra', 'Onions', 'Oregano', 'Parsley', 'Pea shoots', 'Peppers', 'Pickles, unsweetened', 'Pico de gallo', 'Pumpkin', 'Pumpkin puree', 'Radishes', 'Red leaf lettuce', 'Romaine lettuce', 'Rosemary', 'Rutabaga', 'Salsa, fat-free', 'Sauerkraut', 'Scallions', 'Shallots', 'Spaghetti squash', 'Spinach', 'String beans', 'Summer squash', 'Swiss chard', 'Tarragon', 'Thyme', 'Tomatillos', 'Tomato puree, canned', 'Tomato sauce, canned', 'Tomatoes', 'Turnips', 'Water chestnuts', 'Wax beans', 'Zucchini'],
    'WHOLE-WHEAT PASTA & GRAINS': ['Air-popped popcorn, no oil or sugar', 'Air-popped popcorn, no oil or sugar, with salt and/or spices', 'Amaranth', 'Ancient grain mix, no seeds', 'Barley', 'Black bean pasta', 'Brown basmati rice', 'Brown rice', 'Brown rice cereal, 100%', 'Brown rice noodles', 'Brown rice pasta', 'Brown rice–quinoa blend', 'Buckwheat', 'Buckwheat soba noodles', 'Bulgur', 'Chickpea pasta', 'Corn pasta', 'Edamame pasta', 'Farro', 'Freekeh', 'Instant brown rice', 'Instant oatmeal, plain', 'Kamut', 'Kasha', 'Lentil pasta', 'Millet', 'Oatmeal, plain', 'Oats', 'Pea pasta', 'Popcorn kernels for at-home popping', 'Quick-cooking barley', 'Quick-cooking brown rice', 'Quick-cooking regular oats', 'Quick-cooking steel-cut oats', 'Quinoa', 'Quinoa pasta', 'Red quinoa', 'Rolled oats', 'Rye berries', 'Shirataki noodles', 'Sorghum', 'Soybean pasta', 'Spelt', 'Spelt berries', 'Steel-cut oats', 'Teff', 'Thai brown rice', 'Tri-color quinoa', 'Wheat berries', 'Whole-grain pasta', 'Whole-grain sorghum', 'Whole-wheat couscous', 'Whole-wheat pasta', 'Wild rice', 'Wild rice–brown rice blend']
}

class zpCalculatorMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: 'GREEN',
            foodValue: "",
            foodList: green,
            foodFilter: null,
            isResult: false,
            browser: null
        };

        this.onCheckClick = this.onCheckClick.bind(this)
        this.calcZeroPointFoods = this.calcZeroPointFoods.bind(this)
        this.setZeroPointFoods = this.setZeroPointFoods.bind(this)
    }

    componentDidMount() {
        this.setZeroPointFoods(this.state.foodList, showAll)

        this.setState({ isResult: true, browser: browserName });
    }

    setZeroPointFoods(foodList, rex) {
        let zpFilter = {}

        for (let key in foodList) {
            let zpResults = []
            let zpFilterFoods = []

            for (var i in foodList[key]) {
                if (foodList[key][i].match(rex)) {
                    zpFilterFoods.push(foodList[key][i])
                }
            }

            let j = 0
            while (j < zpFilterFoods.length) {
                zpResults.push(zpFilterFoods.slice(j, 2 + j));
                j += 2
            }

            zpFilter[key] = zpResults
        }

        let isResult = false

        for (let k in zpFilter) {
            if (zpFilter[k].length > 0) {
                isResult = true
            }
        }

        this.setState({ foodFilter: zpFilter, isResult: isResult })
    }

    setListClassName() {
        const { color } = this.state

        return color.toLowerCase()
    }

    onCheckClick(e) {
        let color = e.target.nextSibling.innerHTML

        this.setState({ foodValue: "", foodFilter: null })

        if (color === "GREEN") {
            this.setState({ color: color, foodList: green });
            this.setZeroPointFoods(green, showAll)
        }
        else if (color === "BLUE") {
            this.setState({ color: color, foodList: blue });
            this.setZeroPointFoods(blue, showAll)
        }
        else if (color === "PURPLE") {
            this.setState({ color: color, foodList: purple });
            this.setZeroPointFoods(purple, showAll)
        }
    }

    calcZeroPointFoods(e) {
        const { foodList } = this.state

        this.setState({ foodValue: e.target.value })

        if (e.target.value.length === 0) {
            this.setZeroPointFoods(foodList, new RegExp(".*", "i"))
        }
        else {
            this.setZeroPointFoods(foodList, new RegExp(e.target.value, "i"))
        }
    }

    render() {
        const { color, foodValue, foodFilter, isResult, browser } = this.state

        return (
            <Form onSubmit={this.calcSmartPoints} style={isMobile ? { width: '95%', margin: 'auto', marginTop: '10px' } : { width: '80%', margin: 'auto', marginTop: '10px' }} autoComplete="off">
                {!browser ?
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ position: 'relative', fontSize: '.875rem' }}><span style={{ fontWeight: 'bold', color: '#dc3545' }}>Warning</span>: For security reasons, if you are using Internet Explorer you must upgrade to Edge to use this calculator.</p>
                    </div>
                    : null}
                <h1 className="calc-header-mobile">Weight Watchers Zero Point Foods</h1>
                <div className="calc-body">
                    <Col sm="6" style={{ margin: 'auto' }}>
                        <Form.Group style={{ textAlign: 'center', marginTop: '1rem', marginBottom: 0 }}>
                            <Form.Check inline onChange={this.onCheckClick} className="zp-checkbox" style={{ color: '#006400', fontWeight: 'bold' }} type="checkbox" label="GREEN" checked={color === 'GREEN' ? true : false} />
                            <Form.Check inline onChange={this.onCheckClick} className="zp-checkbox" style={{ color: '#0d4f8b', fontWeight: 'bold' }} type="checkbox" label="BLUE" checked={color === 'BLUE' ? true : false} />
                            <Form.Check inline onChange={this.onCheckClick} className="zp-checkbox" style={{ color: '#8f1965', fontWeight: 'bold' }} type="checkbox" label="PURPLE" checked={color === 'PURPLE' ? true : false} />
                        </Form.Group>
                        <Form.Control
                            id="zeropoint"
                            name="zeropoint"
                            type="text"
                            className="calc-input"
                            style={{ position: 'relative', top: '.5rem' }}
                            placeholder="Type a food to filter the list!"
                            onChange={this.calcZeroPointFoods}
                            value={foodValue}
                        >
                        </Form.Control>
                    </Col>
                    <div style={{ position: 'relative' }}>
                        {/* <h2 style={{ position: 'absolute', top: '1rem', left: '2.5rem', textAlign: 'center', fontSize: '.95rem', fontWeight: 600 }}>{color.charAt(0) + color.slice(1).toLowerCase()} zero point foods:</h2> */}
                        {isResult ?
                            <div style={{ width: '100%', margin: '2rem auto', background: '#fff', borderRadius: '1rem', border: '1px solid #a865e1' }}>
                                <div style={{ width: '100%', maxHeight: '30rem', borderRadius: '1rem', overflowY: 'scroll' }}>
                                    {Object.keys(foodFilter).map((key) => (
                                        <React.Fragment key={key}>
                                            {foodFilter[key].length > 0 ?
                                                <div style={{ width: '95%', margin: '2rem auto' }}>
                                                    <p className={this.setListClassName()} style={{ textAlign: 'center', fontWeight: '600', fontSize: '1.25rem' }}>{foodFilter[key].length > 0 ? key : null}</p>
                                                    <Table bordered style={{ width: 'auto' }}>
                                                        <tbody>
                                                            {foodFilter[key].map((foods) => (
                                                                <tr key={foods}>
                                                                    {foods.map((food, i) => (
                                                                        <React.Fragment key={i}>
                                                                            {food.split(',').length > 1 ?
                                                                                <td key={food} style={{ padding: '.5rem', textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                    {food.split(',')[0]}
                                                                                    <i>{',' + food.split(',')[1]}</i>
                                                                                </td>
                                                                                :
                                                                                <td key={food} style={{ padding: '.5rem', textAlign: 'center', verticalAlign: 'middle' }}>
                                                                                    {food}
                                                                                </td>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                : null}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            :
                            <div style={{ width: '94%', height: '20rem', margin: '2rem auto', textAlign: 'center', background: '#fff', borderRadius: '1rem', border: '1px solid #a865e1' }}>
                                <p style={{ marginTop: '2rem' }}>There are no results to display.</p>
                            </div>
                        }
                    </div>
                </div>
            </Form>
        );
    }
}

export default zpCalculatorMobile;