import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

class AllowanceButton extends Component {
    state = {}

    setButtonClass() {
        let windowWidth = this.props.windowWidth

        if (windowWidth > 1024) {
            return "ap-button-fixed"
        }
        else if (windowWidth > 600) {
            return "ap-button-mobile-wide"
        }
        else {
            return "ap-button-mobile"
        }
    }

    render() {
        return (
            <Button variant="info" className={"ap-button" + this.props.buttonClass} onClick={() => window.location.href = "/allowance-calculator"}>Allowance Calculator</Button>
        );
    }
}

export default AllowanceButton;