import React, { Component } from 'react';
import { isMobile, browserName } from 'react-device-detect';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../../styles/calculator.css';


class PpCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browser: null
        };

        this.calcPointPlus = this.calcPointPlus.bind(this)
        this.calcReset = this.calcReset.bind(this)
    }

    componentDidMount() {
        this.setState({ browser: browserName })
    }

    calcPointPlus(e) {
        e.preventDefault()

        let carbs = parseFloat(e.target.carbs.value)
        let fat = parseFloat(e.target.fat.value)
        let fiber = parseFloat(e.target.fiber.value)
        let protein = parseFloat(e.target.protein.value)

        if (isNaN(carbs)) { carbs = 0 }
        if (isNaN(fat)) { fat = 0 }
        if (isNaN(fiber)) { fiber = 0 }
        if (isNaN(protein)) { protein = 0 }

        let plusPoints = Math.round((protein / 10.94) + (carbs / 9.17) + (fat / 3.89) - (fiber / 12.49));

        if (isNaN(plusPoints)) { plusPoints = 0 }

        if (this.props.windowWidth < 800) {
            e.target.results.value = plusPoints + " pts"
        }
        else {
            e.target.results.value = "The PointPlus™ Value: " + plusPoints + " pts"
        }
    }

    calcReset() {
        let inputs = ['carbs', 'fat', 'fiber', 'protein', 'results']

        for (let i in inputs) {
            document.getElementById(inputs[i]).value = ""
        }
    }

    render() {
        const { browser } = this.state

        return (
            <Form onSubmit={this.calcPointPlus} style={isMobile ? { width: '95%', margin: 'auto', marginTop: '10px' } : { width: '75%', margin: 'auto', marginTop: '10px' }} autoComplete="off">
                {!browser ?
                    <div style={{ textAlign: 'center' }}>
                        <p style={{ position: 'relative', fontSize: '.875rem' }}><span style={{ fontWeight: 'bold', color: '#dc3545' }}>Warning</span>: For security reasons, if you are using Internet Explorer you must upgrade to Edge to use this calculator.</p>
                    </div>
                    : null}
                <h1 className={this.props.windowWidth < 500 ? "calc-header-mobile" : "calc-header"}>Weight Watchers Points Plus Calculator</h1>
                <div className="calc-body">
                    <Col sm="6" style={{ margin: 'auto' }}>
                        <Form.Label className="calc-label">CARBS (g)</Form.Label>
                        <Form.Control
                            id="carbs"
                            name="carbs"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">FAT (g)</Form.Label>
                        <Form.Control
                            id="fat"
                            name="fat"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">FIBER (g)</Form.Label>
                        <Form.Control
                            id="fiber"
                            name="fiber"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Form.Label className="calc-label">PROTEIN (g)</Form.Label>
                        <Form.Control
                            id="protein"
                            name="protein"
                            type="number"
                            className="calc-input"
                            min="0"
                            step="0.01"
                        >
                        </Form.Control>
                        <Button onClick={this.calcReset} type="button" className="calc-button" variant="danger">Reset</Button>
                        <Button type="submit" className="calc-button" style={{ float: 'right' }}>Submit</Button>
                    </Col>
                    <div className="calc-results">
                        <Form.Control
                            id="results"
                            name="results"
                            type="text"
                            readOnly
                        >
                        </Form.Control>
                    </div>
                </div>
            </Form>
        );
    }
}

export default PpCalculator;