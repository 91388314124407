import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SpCalculator from '../calculators/sp-calculator';
import Footer from '../footer';
import greenZpBar from '../../images/green-zp-bar.png';
import blueZpBar from '../../images/blue-zp-bar.png';
import purpleZpBar from '../../images/purple-zp-bar.png';
import SmartPointButton from '../buttons/smart-point-button';
import AllowanceButton from '../buttons/allowance-button';
import PointPlusButton from '../buttons/points-plus-button';
import ZeroPointButton from '../buttons/zero-point-button';
import BannerDesktop from '../app-banners/banner-desktop';
import BannerMobileLarge from '../app-banners/banner-mobile-large';
import GoogleSquareAd from '../google-square-ad';


class IndexDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            title: "Weight Watchers Points Calculator",
            windowWidth: window.outerWidth
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }
        }, 50);
    }

    setWelcomeClass() {
        let innerWidth = window.innerWidth
        let outerWidth = window.outerWidth
        let screenWidth = window.screen.width

        if ((innerWidth > 0 && outerWidth > 0 && screenWidth > 0) && (innerWidth <= 1024 || outerWidth <= 1024 || screenWidth <= 1024)) {
            return "welcome-heading-mobile"
        }
        else {
            return "welcome-heading"
        }
    }

    render() {
        const { windowType, windowWidth } = this.state

        return (
            <React.Fragment>
                {windowWidth < 1150 ? <BannerMobileLarge windowWidth={windowWidth} /> : <BannerDesktop windowWidth={windowWidth} />}
                {windowWidth > 800 ? <div className="triangle-bkgd"></div> : null}
                <div className={"container container-home" + windowType} style={{ marginTop: '2.5rem' }}>
                    <div style={{ marginTop: '.5rem' }}><GoogleSquareAd /></div>
                    <SpCalculator title={this.state.title} windowWidth={this.state.windowWidth} />
                    <Row>
                        <Col>
                            <AllowanceButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <PointPlusButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <SmartPointButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <ZeroPointButton buttonClass="-desktop" />
                        </Col>
                    </Row>
                    <div className={"page-content" + windowType}>
                        <h2>What is Weight Watchers and How Does it Work?</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>Weight Watchers™ (aka WW™) is and always will be to some degree a weight loss program. I mean, the notion of watching one's weight is in the literal brand name. But the differences in their approach compared to many other fad, get-thin-quick schemes are to focus on building a long-term, healthy lifestyle. They understand that a person's weight serves as an observable indication of unhealthy or suboptimal daily habits. Thus, by using the latest in behavioral science, their goal is to isolate and challenge these damaging subconscious tendencies, our poor habits, and provide the guidance, motivation, and inspiration for mandatory life changes that will lead to losing weight and keeping it off.</p>
                            <p>Because the ultimate aim of Weight Watchers is to improve one's overall lifestyle, the new myWW program begins with a 25+ question personal assessment to provide you with an individualized plan, tailored toward your success with their program. These questions will cover things like:</p>
                            <p style={{ fontWeight: '500', textTransform: 'uppercase' }}>1. How you feel in general and your mindset toward weight loss</p>
                            <ul>
                                <li>There have been scientific studies suggesting women, and I assume the same goes for men, who are dissatisfied with their bodies are more likely to avoid exercise and that simply thinking you are overweight can predict future weight gain. For additional information, refer to this <a target="_blank" rel="noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/18086724">PubMed Abstract</a>.</li>
                                <li>The mentality that you approach weight loss is paramount to your long-term success with it. Approach it through the lens of self-doubt and self-deprecation and there is a good chance you will fail without additional guidance. These questions are designed to calculate this potential risk factor and allows Weight Watchers to gauge how they approach your weight loss plan.</li>
                            </ul>
                            <p style={{ fontWeight: '500', textTransform: 'uppercase' }}>2. How you approach food</p>
                            <ul>
                                <li>This section contains questions about when, how much, and how often you eat. At night? When you're bored? Is portion control tricky for you? Although there is no concrete evidence humans eating before bed causes weight gain (although it does in mice), portion control is a key aspect of the weight watchers system and will be addressed in section, if it is an issue for you.</li>
                            </ul>
                            <p style={{ fontWeight: '500', textTransform: 'uppercase' }}>3. Your physical attributes and activity level</p>
                            <ul>
                                <li>These are questions around height, weight, and physical activity level. This is asked primarily to calculate your Total Daily Energy Expenditure (TDEE) and so that they get a baseline for your daily smart point budget. For more information and a detailed explanation of TDEE and why it's important, you can visit our <a href="/allowance-calculator">Allowance Calculator</a> page.</li>
                            </ul>
                            <p style={{ fontWeight: '500', textTransform: 'uppercase' }}>4. How you sleep and your sleeping habits</p>
                            <ul>
                                <li>Sleep is equal in importance to diet and exercise. Not enough sleep or sleep irregularity can cause a myriad of health and weight-related issues (The <a target="_blank" rel="noreferrer" href="https://www.webmd.com/diet/sleep-and-weight-loss#1">WebMD sleep more, weigh less article</a> analyzes this medical fact nicely). This tells WW whether or not sleep-related material and assistance should be a focal point in your program.</li>
                            </ul>
                            <p style={{ fontWeight: '500', textTransform: 'uppercase' }}>5. Personality traits and how you handle setbacks</p>
                            <ul>
                                <li>As we mentioned earlier, a positive mental attitude toward weight loss and how it's positioned in the mind is critical for continued success with your plan. This section will allow the WW team to provide you with helpful and inspirational material to power you through setbacks and negative thinking.</li>
                            </ul>
                            <p>As you can see, their assessment questions are far from random. Each topic has been chosen because science has proven, or at the very least strongly suggests these components of a weight loss plan work in sympathy to achieve a successful outcome.</p>
                            <p>The result of this questionnaire not only illuminates potential risk areas that could lead to failure, but also allows Weight Watchers to recommend a plan of Green, Blue, Purple, or Freestyle that best fits your personality traits and behavioral tendencies. For more information on these plans and how they relate, you can visit our <a href="/weight-watchers-zero-point-foods">Zero Point Foods</a> and <a href="/allowance-calculator">Allowance Calculator</a> pages, but the condensed version is as follows:</p>
                            {window.outerWidth < 1200 || window.innerWidth < 1200 ?
                                <React.Fragment>
                                    <p style={{ marginTop: '3rem' }}>
                                        <span style={{ float: 'left', fontSize: '1.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#016c5b', marginTop: '.5rem', marginLeft: '.5rem' }}>Green</span>
                                        <span style={{ float: 'left', fontSize: '1.5rem', marginTop: '.5rem', marginLeft: '.5rem' }}>100+ Zero Point Foods</span>
                                    </p>
                                    <div>
                                        <Image src={greenZpBar} style={{ width: '80%', marginTop: '1rem' }} />
                                    </div>
                                    <p>
                                        <span style={{ float: 'left', fontSize: '1.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#006fbc', marginTop: '.5rem', marginLeft: '.5rem' }}>Blue</span>
                                        <span style={{ float: 'left', fontSize: '1.5rem', marginTop: '.5rem', marginLeft: '.5rem' }}>200+ Zero Point Foods</span>
                                    </p>
                                    <div>
                                        <Image src={blueZpBar} style={{ width: '80%', marginTop: '1rem' }} />
                                    </div>
                                    <p>
                                        <span style={{ float: 'left', fontSize: '1.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#911b67', marginTop: '.5rem', marginLeft: '.5rem' }}>Purple</span>
                                        <span style={{ float: 'left', fontSize: '1.5rem', marginTop: '.5rem', marginLeft: '.5rem' }}>300+ Zero Point Foods</span>
                                    </p>
                                    <div>
                                        <Image src={purpleZpBar} style={{ width: '80%', marginTop: '1rem' }} />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <p style={{ marginTop: '3rem' }}>
                                        <span style={{ position: 'relative', top: '.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#016c5b' }}>Green</span>
                                        <span style={{ position: 'relative', top: '.5rem', left: '2.5rem' }}>100+ Zero Point Foods</span>
                                        <span><Image src={greenZpBar} style={{ width: '25rem', float: 'right' }} /></span>
                                    </p>
                                    <p>
                                        <span style={{ position: 'relative', top: '.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#006fbc' }}>Blue</span>
                                        <span style={{ position: 'relative', top: '.5rem', left: '3.6rem' }}>200+ Zero Point Foods</span>
                                        <span><Image src={blueZpBar} style={{ width: '25rem', float: 'right' }} /></span></p>
                                    <p>
                                        <span style={{ position: 'relative', top: '.5rem', fontWeight: '600', textTransform: 'uppercase', color: '#911b67' }}>Purple</span>
                                        <span style={{ position: 'relative', top: '.5rem', left: '2rem' }}>300+ Zero Point Foods</span>
                                        <span><Image src={purpleZpBar} style={{ width: '25rem', float: 'right' }} /></span>
                                    </p>
                                </React.Fragment>
                            }
                            <p style={{ marginTop: '4rem' }}>Although the structure of each plan may vary, the central purpose remains consistent. To leverage scientific principles and equations and translate them into an easily understandable point-based system. By assigning each food a numerical point value and each individual a maximum number of points they may consume each day, the myWW program can accurately predict within acceptable deviations the calories a person needs to consume a day to lose 1 to 2 pounds a week.</p>
                            <p>At first glance, this might seem like a small, painstakingly slow margin of weight loss, but when you realize you must consume 1000 fewer calories a day for a week to lose 2 pounds, it's a huge challenge and something to be proud of if accomplished! After all, it is the <a target="_blank" rel="noreferrer" href="https://www.cdc.gov/healthyweight/losing_weight/index.html">CDC recommended rate for healthy and sustained weight loss</a> and Weight Watchers itself is the #1 doctor recommended weight loss program, based on an IQVIA survey of 14,000 doctors!</p>
                        </div>
                        <h2>What are Weight Watchers Points?</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>In short, the Weight Watchers Points, also referred to as the WW Smart Point system, assigns every food and drink a number value based on the calories, saturated fat, sugar and protein it contains. For example, a slice of regular cheese pizza will cost 7 smart points. The smart point itself is designed to simplify the complexities of nutrition science into a more user-friendly format.  For more information on how to calculate weight watchers points, you can visit our <a href="/weight-watchers-smart-points-calculator">smart point calculator</a> page.</p>
                            <p>It's important to understand that the numeric value of a smart point doesn't necessarily mean much by itself.  It must be measured in conjunction with a daily and weekly smart point allowance, a personal maximum threshold of smart points allowed per day combined with a static weekly amount to maintain the goal of losing about 2 pounds a week.  Thus, the SmartPoint remains the fundamental unit of currency used to accurately monitor daily consumption habits within your WW health program!</p>
                        </div>
                    </div>
                </div>
                <Footer />
                <Helmet>
                    <title>Weight Watchers Points Calculator | WW Points Calculator</title>
                    <meta name='description' content="This is a free to use weight watchers points calculator, using which you can determine any food items point value. Also find out your daily allowance & much more!" />
                    <meta name="keywords" content="weight watchers points calculator, smart points calculator, weight watchers smart points calculator, ww points calculator, ww calculator, free weight watchers calculator, weight watchers calculator app" />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default IndexDesktop;