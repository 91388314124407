import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import mBMR from '../../images/bmr-men.png';
import wBMR from '../../images/bmr-women.png';
import Example1 from '../../images/bmr-example-1.png';
import Example2 from '../../images/bmr-example-2.png';
import Example3 from '../../images/bmr-example-3.png';
import TDEE1 from '../../images/tdee-eq-1.png';
import TDEEPAL from '../../images/tdee-pal-example.png';
import BMRPAL from '../../images/bmr-pal-segment.png';
import PALResult from '../../images/tdee-pal-result.png'
import TEF from '../../images/tef-eq.png';
import TEF1 from '../../images/tef-eq-1.png';
import TEF2 from '../../images/tef-eq-2.png';
import TDEESolution1 from '../../images/tdee-solution-1.png';
import TDEESolution2 from '../../images/tdee-solution-2.png';
import DSP1 from '../../images/dsp-1.png';
import DSP2 from '../../images/dsp-2.png';
import TDC1 from '../../images/tdc-1.png';
import TDC2 from '../../images/tdc-2.png';
import FinalBoss from '../../images/final-boss.png'
import SmartPointButton from '../buttons/smart-point-button';
import PointPlusButton from '../buttons/points-plus-button';
import ZeroPointButton from '../buttons/zero-point-button';
import Footer from '../footer';
import '../../styles/calculator.css';
import ApCalculator from '../calculators/ap-calculator';
import GoogleSquareAd from '../google-square-ad';
import BannerDesktop from '../app-banners/banner-desktop';
import BannerMobileLarge from '../app-banners/banner-mobile-large';



class AllowanceCalculatorDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            windowWidth: window.outerWidth
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }
        }, 50);
    }

    render() {
        const { windowType, windowWidth } = this.state

        return (
            <React.Fragment>
                {windowWidth < 1150 ? <BannerMobileLarge windowWidth={windowWidth} /> : <BannerDesktop windowWidth={windowWidth} />}
                <div className={"container" + windowType}>
                    {windowWidth > 800 ? <div className="triangle-bkgd"></div> : null}
                    <div style={{ marginTop: '.5rem' }}><GoogleSquareAd /></div>
                    <ApCalculator windowWidth={this.state.windowWidth} />
                    <Row>
                        <Col>
                            <SmartPointButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <PointPlusButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <ZeroPointButton buttonClass="-desktop" />
                        </Col>
                    </Row>
                    <div className={"page-content" + windowType}>
                        <h2>Weight Watchers Freestyle Points Allowance</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>The purpose behind Weight Watchers™ daily smart point allowance can be summarized within one simple principal, to maintain a daily caloric intake lower than that which would otherwise maintain your current bombshell physique.</p>
                            <p>It's that simple. The daily points you are allocated force you to eat less than what your body needs to stay at its current weight.</p>
                            <p>Thus, your daily point allowance is designed to assist you in losing between 1 to 2 pounds per week.  Although two pounds a week might not sound like a lot at first, it is the <a href="https://www.cdc.gov/healthyweight/losing_weight/index.html">CDC recommended rate for healthy, sustainable weight loss</a> and as we step through some of the equations used to derive the Weight Watcher point system, it will become more evident that a little is actually a lot when it comes to losing weight!</p>
                        </div>
                        <h2 style={{ fontSize: '40px' }}>How The Weight Watchers Freestyle Points Allowance Calculator Works</h2>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <div className="line-shape"></div>
                            <p>To answer this, we need to start by breaking down the main components Weight Watchers uses to determine the daily points allowance that would sustain your current weight.  From there we can infer the SmartPoint allowance necessary for healthy, sustainable weight loss.  These are the three main processes of metabolism WW uses to calculate your daily points:</p>
                            <p style={{ fontSize: '24px' }}>1. Basal Metabolic Rate (BMR)</p>
                            <ul style={{ listStyleType: 'square' }}>
                                <li>Consumes between 60-70% of the energy we burn each day</li>
                                <li>For example, keeping our heart beating & mechanical processes such as breathing</li>
                            </ul>
                            <p style={{ fontSize: '24px' }}>2. Physical Activity Level (PAL)</p>
                            <ul style={{ listStyleType: 'square' }}>
                                <li>Consumes between 20-30% of the energy we burn each day</li>
                                <li>Increases as the intensity of activity increases</li>
                            </ul>
                            <p style={{ fontSize: '24px' }}>3. Food Digestion</p>
                            <ul style={{ listStyleType: 'square' }}>
                                <li>Accounts for about 10% of our daily energy consumption.</li>
                                <li>Also referred to as the thermic effect of food (TEF)</li>
                            </ul>
                            <p>By assigning numerical values to these metabolic components, we can esimate our Total Daily Energy Expediture (TDEE), which will represent the calories we would need each day to sustain our current body weight.  From there, losing weight is as simple as following a system like Weight Watchers to ensure we consume between 500 and 1000 less calories per day in order to meet our objective of losing 1 to 2 pounds per week!</p>
                            <p>This is the equation we are going to solve for in the follow example:</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDEE1} alt="Total Daily Energy Expenditure (TDEE) Equation" style={{ width: '22rem', marginTop: '1rem' }} />
                            </div>
                            <p>Where BMR stands for Basal Metabolic Rate, PAL stands for Physical Activity Level, and TEF stands for the Thermic Effect of Food.  Once we know our TDEE value, we can use it as a baseline for our projected daily points allowance.</p>
                            <p>These are the steps how to do it...</p>
                            <p style={{ marginTop: '4rem', fontSize: '32px' }}>1. Calculate BMR (Basal Metabolic Rate)</p>
                            <p>Unless you know your exact body fat percentage, Mifflin-St and Jeor's (1990) revision of the original Harris-Benedict equation (1918), is our best predictive indicator of BMR to date.  It requires your height, weight, age, and gender in order to estimate BMR within ±200 calories.</p>
                            <p>BMR For Men:</p>
                            {windowType === '-mobile' ?
                                <React.Fragment>
                                    <div>
                                        <Image src={mBMR} style={{ width: '38rem', marginTop: '1rem' }} alt="BMR Equation For Men" />
                                    </div>
                                    <p>BMR For Women:</p>
                                    <div>
                                        <Image src={wBMR} style={{ width: '42rem', marginTop: '1rem' }} alt="BMR Equation For Women" />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div style={{ marginLeft: '2rem' }}>
                                        <Image src={mBMR} style={{ width: '45rem', marginTop: '1rem' }} alt="BMR Equation For Men" />
                                    </div>
                                    <p>BMR For Women:</p>
                                    <div style={{ marginLeft: '2rem' }}>
                                        <Image src={wBMR} style={{ width: '48.5rem', marginTop: '1rem' }} alt="BMR Equation For Women" />
                                    </div>
                                </React.Fragment>
                            }
                            <p>If you're wondering how these seemingly random values were determined, they were proven through regression analysis of experimental data.  Ok, now that we know the equations, let's calculate the BMR for a 35 year old male with the following attributes:</p>
                            <Table style={{ width: '22rem', margin: '2.5rem auto 1.5rem auto', textAlign: 'center', borderLeft: '1px solid #dee2e6', borderRight: '1px solid #dee2e6', borderBottom: '1px solid #dee2e6' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>Gender</td>
                                        <td style={{ fontStyle: 'italic' }}>Male</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>Age</td>
                                        <td style={{ fontStyle: 'italic' }}>35 years</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>Height</td>
                                        <td style={{ fontStyle: 'italic' }}>5' 10'' <span style={{ fontStyle: 'normal', marginLeft: '.425rem' }}>(178 cm)</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>Weight</td>
                                        <td style={{ fontStyle: 'italic' }}>200 lbs <span style={{ fontStyle: 'normal', marginLeft: '.425rem' }}>(91 kg)</span></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={Example1} alt="BMR Initial Equation and Calculation" style={{ width: '32rem', marginTop: '1rem' }} />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={Example2} alt="BMR Simplified Equation and Calculation" style={{ width: '22.25rem', marginTop: '1rem' }} />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={Example3} alt="BMR Calculation Solution" style={{ width: '17rem', marginTop: '1.25rem' }} />
                            </div>
                            <p>Therefore, a five-foot-ten, two hundred pounds, thirty-five year old male will burn about 1,853 calories simply by getting out of bed in the morning and navigating a typical daily routine.</p>
                            <p style={{ marginTop: '4rem', fontSize: '32px' }}>2. Factor in Physical Activity Level (PAL)</p>
                            <p>Now that we have calculated BMR, it's time to factor in physical activity level (PAL).  The following table describes activity levels and their associated PAL multiplier:</p>
                            <Table style={{ marginTop: '3rem', border: '1px solid #dee2e6' }} striped>
                                <thead style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    <tr>
                                        <td>Activity Level</td>
                                        <td>Description</td>
                                        <td>PAL</td>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '.925rem' }}>
                                    <tr>
                                        <td style={{ fontStyle: 'italic' }}>Inactive/Sedentary</td>
                                        <td>Desk job with no option to move around; little to no strenuous leisure activity</td>
                                        <td>1.25</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontStyle: 'italic' }}>Light Activity</td>
                                        <td>Desk job that requires you to move around; little to no strenuous leisure activity</td>
                                        <td>1.5</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontStyle: 'italic' }}>Moderate Exercise</td>
                                        <td>Standing work; Some strenuous leisure activity</td>
                                        <td>1.75</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontStyle: 'italic' }}>Heavy Exercise</td>
                                        <td>Strenuous work or highly strenuous leisure activity</td>
                                        <td>2.0</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p style={{ marginTop: '-1rem', textAlign: 'right', fontSize: '.85rem', fontStyle: 'italic' }}>Multipliers may slightly differ depending on source</p>
                            <p>We can now use one of these PAL to solve for the<span><Image src={BMRPAL} alt="BMR and PAL Segment Of TDEE Equation" style={{ width: '8rem' }} /></span>segment of the TDEE equation.  Let's continue our example by factoring in a physical activity level of <span style={{ fontStyle: 'italic' }}>Inactive/Sedentary</span> (since most of us will fit into this category anyway) using the BMR from our previous calculation.</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDEE1} style={{ marginTop: '1rem', width: '20rem' }} alt="TDEE Equation With PAL Multiplier" />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDEEPAL} style={{ marginTop: '1rem', width: '21rem' }} alt="TDEE Equation With PAL Multiplier" />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={PALResult} style={{ marginTop: '1rem', width: '15rem' }} alt="BMR and PAL Multiplier Solution" />
                            </div>
                            <p>Great, let's move on to the final step in calculating Total Energy Expenditure!</p>
                            <p style={{ fontSize: '32px' }}>3. Account for the Thermic Effect of Food (TEF)</p>
                            <p>Now that we have accounted for Basal Metabolic Rate and Physical Activity Level, the final metabolic component that affects TDEE is food digestion, which is scientifically referred to as the Thermic Effect of Food or TEF for short.</p>
                            <p>If you recall from earlier, food digestion can be measured to consume an additional 10% of daily energy consumption, therefore, our TEF equation will do just that:</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TEF} style={{ marginTop: '1rem', width: '14rem' }} alt="Thermic Effect of Food TEF Equation" />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TEF1} style={{ marginTop: '1rem', width: '14.5rem' }} alt="Thermic Effect of Food TEF Equation With Input Variables" />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TEF2} style={{ marginTop: '1rem', width: '11rem' }} alt="Thermic Effect of Food TEF Equation Solution" />
                            </div>
                            <p>We've successfully solved for all the elements necessary to calculate TDEE in our example, so let's do that!</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDEESolution1} style={{ marginTop: '1rem', marginLeft: '.5rem', width: '15.5rem' }} alt="TDEE Completed Equation With Variables" />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDEESolution2} style={{ marginTop: '1rem', marginLeft: '.5rem', width: '16.5rem' }} alt="TDEE Example Solution" />
                            </div>
                            <p>And... we're done! This means that our 5ft 10in, 200lb, 35 year-old, sedentary male will consume about 2,501 calories a day just by existing and not making any additional effort to burn calories or consume energy.  What this number also indicates, are the number of calories this individual needs to consume to remain his current weight.</p>
                            <p>Once we're armed with this number, losing weight is as simple as dropping our daily caloric intake below this value at a rate of 500 calories per pound! The following chart demonstrates how our daily intake would need to be adjusted to lose 1, 1.5, and 3 pounds a week:</p>
                            <Table style={{ width: '22rem', margin: '3rem auto 0 auto' }} bordered>
                                <thead style={{ textAlign: 'center', fontSize: '.95rem' }}>
                                    <tr>
                                        <th style={{ borderBottom: 'none' }}>Pounds Per Week</th>
                                        <th style={{ borderBottom: 'none' }}>Calories Per Day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1 pound</td>
                                        <td>2,001 calories</td>
                                    </tr>
                                    <tr>
                                        <td>1.5 pounds</td>
                                        <td>1,751 calories</td>
                                    </tr>
                                    <tr>
                                        <td>2 pounds</td>
                                        <td>1,501 calories</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p style={{ textAlign: 'center', marginTop: '-.25rem', marginLeft: '4.5rem', fontSize: '.85rem', fontStyle: 'italic' }}>calories are short-hand for kilocalories (kcal)</p>
                            <p>To lose between 1 and 2 pounds a week, the individual in our example would need to consume between 1,501 and 2,001 calories a day. Now that we have these numerical metrics to reference, it's easy to see why the CDC recommends this range as an optimal weight loss rate, 1,000 fewer calories a day is a lot!</p>
                            <p>That's great and all, but...</p>
                        </div>
                        <h2 style={{ fontSize: '40px' }}>How Does TDEE Relate To My Daily Points Allowance?</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>In order to answer this, we must first calculate the daily smart point allowance for our example using the Weight Watchers formula:</p>
                            <Table style={{ margin: 'auto', width: 'auto', marginTop: '2rem' }} bordered>
                                <tbody style={{ fontSize: '18px', fontWeight: '300' }}>
                                    <tr>
                                        <td>If you are a male</td>
                                        <td style={{ textAlign: 'center' }}>8 points</td>
                                    </tr>
                                    <tr>
                                        <td>Between ages 27-37</td>
                                        <td style={{ textAlign: 'center' }}>3 points</td>
                                    </tr>
                                    <tr>
                                        <td>One point per 10 lbs</td>
                                        <td style={{ textAlign: 'center' }}>20 points</td>
                                    </tr>
                                    <tr>
                                        <td>5 ft 10 in +</td>
                                        <td style={{ textAlign: 'center' }}>2 points</td>
                                    </tr>
                                    <tr>
                                        <td>Sedentary/Inactive</td>
                                        <td style={{ textAlign: 'center' }}>0 points</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: '500' }}>Total</td>
                                        <td style={{ textAlign: 'center', fontWeight: '500' }}>33 points</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>The Weight Watchers method allows our example participant 33 daily smart points, in addition to 49 weekly points.  If we factor in our weekly smart points as an average per day, we would get:</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={DSP1} alt="Daily Smart Point Calculation Including Weekly Points" style={{ width: '25rem', marginTop: '1rem' }} />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={DSP2} alt="Daily Smart Point Solution Including Weekly Points" style={{ width: '16.75rem', marginTop: '1rem' }} />
                            </div>
                            <p style={{ marginTop: '2rem' }}>So, on average, we have 40 smart points to spend per day.  Although, this value is accurate for our first week, it should be recalculated each week to account for any weight loss that could affect the allowance value.</p>
                            <p>Now that we have solved for TDEE (the number of calories to sustain our current weight) and our daily smart point allowance, I’d imagine the most common mistake at this point would be to correlate smart points directly to calories. This is because not all calories are created equal and their relative worth will differ depending on their origin. One way to visualize how Weight Watchers stresses this point is to take saturated fat, sugar, and protein and represent them through calories per smart point.</p>
                            <Table style={{ margin: 'auto', width: 'auto', marginTop: '3rem' }} bordered>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Nutrient Type</th>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Calories Per Smart Point</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '18px', fontWeight: '300' }}>
                                    <tr>
                                        <td>Saturated Fat</td>
                                        <td style={{ textAlign: 'center' }}>17</td>
                                    </tr>
                                    <tr>
                                        <td>Sugar</td>
                                        <td style={{ textAlign: 'center' }}>24</td>
                                    </tr>
                                    <tr>
                                        <td>Protein</td>
                                        <td style={{ textAlign: 'center' }}>48</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: '500' }}>Average</td>
                                        <td style={{ textAlign: 'center', fontWeight: '500' }}>~ 30</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p style={{ marginTop: '3rem' }}>Thus, if you come across a thread or post that indicates a smart point is equivalent to 30 calories, this is why.  However, as we’ll find out, this is a bit of a misconception.  Because, if we take this arithmetic mean and apply it to our adjusted daily allowance points, we get:</p>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDC1} alt="Total Daily Calories Calculation" style={{ width: '22.5rem', marginTop: '1rem' }} />
                            </div>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={TDC2} alt="Total Daily Calories Solution" style={{ width: '20.5rem', marginTop: '1rem' }} />
                            </div>
                            <p style={{ marginTop: '2rem' }}>Considerably lower than what we should expect, which would be somewhere between 1,500 and 2,000 calories to achieve our weight loss goals healthily and sustainably. Thus, taking the arithmetic mean of calories per smart point, in essence, the assumption that all calories are created equal is not the recipe Weight Watchers subscribes to.</p>
                            <p>But what if we take our calorie per smart point metrics and weight them to align with CDC recommended guidelines for saturated fat and sugar consumption? Both of which should account for 10% of your daily caloric intake. So, let’s allocate these percentages to our daily smart points and analyze the results:</p>
                            <Table style={{ margin: 'auto', width: 'auto', marginTop: '3rem' }} bordered>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Nutrient Type</th>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Weighted Point Value</th>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Points × Calories Per SmartPoint</th>
                                        <th style={{ textAlign: 'center', borderBottom: 'none' }}>Calories</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '18px', fontWeight: '300' }}>
                                    <tr>
                                        <td>Saturated Fat</td>
                                        <td style={{ textAlign: 'center' }}>4 points (10% of 40 points)</td>
                                        <td style={{ textAlign: 'center' }}>4 × 17</td>
                                        <td style={{ textAlign: 'center' }}>68</td>
                                    </tr>
                                    <tr>
                                        <td>Sugar</td>
                                        <td style={{ textAlign: 'center' }}>4 points (10% of 40 points)</td>
                                        <td style={{ textAlign: 'center' }}>4 × 24</td>
                                        <td style={{ textAlign: 'center' }}>96</td>
                                    </tr>
                                    <tr>
                                        <td>Protein</td>
                                        <td style={{ textAlign: 'center' }}>32 points (80% of 40 points)</td>
                                        <td style={{ textAlign: 'center' }}>32 × 48</td>
                                        <td style={{ textAlign: 'center' }}>1536</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div style={{ marginLeft: '2rem' }}>
                                <Image src={FinalBoss} alt="Smart Point to TDEE correlation Final Equation" style={{ width: '22.5rem', marginTop: '1rem' }} />
                            </div>
                            <p style={{ marginTop: '3rem' }}>Which equals about 800 fewer calories a day from our original TDEE value of 2,501 calories and if we translate that into an expected weight loss rate, it would be 1.6 pounds per week - exactly where we need to be!</p>
                        </div>
                    </div>
                </div>
                <Footer />
                <Helmet>
                    <title>Weight Watchers Smart Points Allowance Calculator | Watchers Point</title>
                    <meta name='description' content="Use this Weight Watchers Smart points Allowance Calculator to find out your daily allowance and get started with your diet. Visit now and get in shape!" />
                    <meta name="keywords" content="weight watchers points allowance calculator, weight watchers freestyle points allowance calculator, smart points allowance calculator, ww freestyle calculator" />
                </Helmet>
            </React.Fragment >
        );
    }
}

export default AllowanceCalculatorDesktop;