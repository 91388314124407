import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Green from '../../images/green-zp-image.png';
import Blue from '../../images/blue-zp-image.png';
import Purple from '../../images/purple-zp-image.png';
import ZpCalculator from '../calculators/zp-calculator';
import SmartPointButton from '../buttons/smart-point-button';
import AllowanceButton from '../buttons/allowance-button';
import PointPlusButton from '../buttons/points-plus-button';
import GoogleSquareAd from '../google-square-ad';
import BannerDesktop from '../app-banners/banner-desktop';
import BannerMobileLarge from '../app-banners/banner-mobile-large';
import Footer from '../footer';

class ZPFoodsDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            windowWidth: window.outerWidth
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }
        }, 50);
    }

    render() {
        const { windowType, windowWidth } = this.state

        return (
            <React.Fragment>
                {windowWidth < 1150 ? <BannerMobileLarge windowWidth={windowWidth} /> : <BannerDesktop windowWidth={windowWidth} />}
                <div className={"container" + windowType}>
                    {windowWidth > 800 ? <div className="triangle-bkgd"></div> : null}
                    <div style={{ marginTop: '.5rem' }}><GoogleSquareAd /></div>
                    <ZpCalculator />
                    <Row>
                        <Col>
                            <SmartPointButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <AllowanceButton buttonClass="-desktop" />
                        </Col>
                        <Col>
                            <PointPlusButton buttonClass="-desktop" />
                        </Col>
                    </Row>
                    <div className={"page-content" + windowType}>
                        <h2>A Brief History of Weight Watchers Zero Point Foods</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>The concept of zero point foods was first introduced by Weight Watchers™ in 2010, with their OG ProPoints™ innovations. The first list of zero point foods started with fruits and non-starchy vegetables, which is referred to today as the Green plan. Daily Allowance points also increased from 22 to 31 points per day while processed foods were calculated as containing more smart points.</p>
                            <p>Since then, the term ZeroPoint™ was officially coined in 2018 and added the WW Freestyle variation, which is currently referred to as the Blue plan. This program includes a separate zero point foods list of 200+ foods with a moderate smart point allowance. It contains the original fruits and vegetable list, but adds a variety of lean proteins to the mix.</p>
                            <p>Presently, there is a third plan available called Purple, which contains the highest number of zero point foods at 300+, although permits the smallest amount of daily smart points. This list contains most Blue plan items in addition to many whole grain options.</p>
                        </div>
                        <h2>What are Zero Point Foods?</h2>
                        <div className="line-shape"></div>
                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                            <p>Quite simply, these are foods that do not cost an individual any daily or weekly smart points to consume. The point behind the option of zero point foods is to provide people an outlet for satiety without having to worry about tracking points or overeating but at the same time support people in developing healthier eating habits.</p>
                            <p>As alluded to before, WW reimagined currently promotes 3 plans, separated by the daily smart points they allow and the number of 0 point foods in their respective lists. Although these plans might be structured in different ways, the goal of developing better eating strategies remains the same. Here's an overview of the 3 options:</p>
                            <div style={{ margin: '4rem 0', textAlign: 'center' }}>
                                <Image src={Green} alt="ww green zero point foods" style={{ marginTop: '1rem', width: '35rem' }}></Image>
                                <Image src={Blue} alt="ww blue zero point foods" style={{ marginTop: '1.25rem', width: '35rem' }}></Image>
                                <Image src={Purple} alt="ww purple zero point foods" style={{ marginTop: '1rem', width: '35rem' }}></Image>
                            </div>
                            <p>Although it’s not a problem to switch among the various plans to follow one that better fits their personality type and tendencies, it’s recommended that someone sticks with the plan that they’re on for about 2 weeks before making that decision. It’s important to realize that just because these foods cost zero smart points, it’s probably suboptimal to view them as a license for overeating or a substitute for moderation. To quote Weight Watchers themselves, “So, if you normally ate one banana a day, stick to that. Now's not the time to start eating four bananas just because they're zero.” (<a target="_blank" rel="noreferrer" href="https://www.weightwatchers.com/us/blog/weight-loss/myww/about-zeropoint-foods">myWW - About Zero Point Foods</a>)</p>
                            <p>Along granting someone with the freedom from point tracking, most of the foods included in your zero point list are affordable for any income brackets and readily available in any local grocery store. Even if some of the foods on your list are foreign to you, there are thousands of recipes accessible through Weight Watchers and third-parties via an online search or physical book.</p>
                        </div>
                    </div>
                </div>
                <Footer />
                <Helmet>
                    <title>Weight Watchers Zero Point Foods Lists</title>
                    <meta name='description' content="Choose the Weight Watchers plan you subscribe to, either Green, Blue, or Purple; then type a food in our verification tool to find out if it costs 0 smart points!" />
                    <meta name="keywords" content="weight watchers blue plan, weight watchers zero point foods, ww zero point foods, ww green plan, ww blue plan, ww purple plan, zero point foods list" />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default ZPFoodsDesktop;