import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import LongEq from '../../images/sp-long-equation.png';
import ShortEq from '../../images/sp-short-equation.png';
import SimpleEq from '../../images/sp-simplest-eq.png';
import Option1 from '../../images/sp-option-1.png';
import Option2 from '../../images/sp-option-2.png';
import FullCSEq from '../../images/full-cal-sugar-eq.png';
import SimpleCSEq from '../../images/simplified-cal-sugar-eq.png';
import RangeEq from '../../images/sp-cs-range-eq.png';
import RangeExample from '../../images/sp-range-example.png';
import UpdatedEqCS from '../../images/sp-eq-with-cs.png';
import Potato1 from '../../images/sp-potato-1.png';
import Potato2 from '../../images/sp-potato-2.png';
import PotatoSolution from '../../images/sp-potato-solution.png';
import ProteinSolved from '../../images/sp-protein-solved.png';
import HotPocket1 from '../../images/sp-hotpocket-1.png';
import HotPocket2 from '../../images/sp-hotpocket-2.png';
import HotPocketSolution from '../../images/sp-hotpocket-solution.png';
import FinalEquation from '../../images/sp-final-equation.png';
import AccPlus from '../../images/accordion-plus.png';
import AccMinus from '../../images/accordion-minus.png';
import SpCalculator from '../calculators/sp-calculator';
import PointPlusButton from '../buttons/points-plus-button';
import AllowanceButton from '../buttons/allowance-button';
import ZeroPointButton from '../buttons/zero-point-button';
import BannerMobile from '../app-banners/banner-mobile';
import BannerMobileLarge from '../app-banners/banner-mobile-large';
import BannerDesktop from '../app-banners/banner-desktop';
import GoogleSquareAd from '../google-square-ad';
import Footer from '../footer';



class SmartPointCalculatorMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            title: "Weight Watchers Smart Points Calculator",
            windowWidth: window.outerWidth,
            expanded: true,
            bannerType: ""
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });

        if (windowWidth <= 620) { this.setState({ bannerType: 'mobile' }) }
        else if (windowWidth <= 1175) { this.setState({ bannerType: 'mobile-large' }) }
        else { this.setState({ bannerType: 'desktop' }) }
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }

            if (windowWidth <= 620) { this.setState({ bannerType: 'mobile' }) }
            else if (windowWidth <= 1175) { this.setState({ bannerType: 'mobile-large' }) }
            else { this.setState({ bannerType: 'desktop' }) }
        }, 50);
    }

    render() {
        const { windowType, windowWidth, bannerType } = this.state

        return (
            <React.Fragment>
                {bannerType === 'mobile' ? <BannerMobile windowWidth={windowWidth} /> : null}
                {bannerType === 'mobile-large' ? <BannerMobileLarge windowWidth={windowWidth} /> : null}
                {bannerType === 'desktop' ? <BannerDesktop windowWidth={windowWidth} /> : null}
                <div className={"container" + windowType}>
                    {windowWidth > 800 ? <div className="triangle-bkgd"></div> : null}
                    <GoogleSquareAd />
                    <SpCalculator title={this.state.title} windowWidth={this.state.windowWidth} />
                    {windowWidth < 500 ?
                        <div style={{ width: '90%', margin: 'auto' }}>
                            <PointPlusButton buttonClass="-mobile" />
                            <AllowanceButton buttonClass="-mobile" />
                            <ZeroPointButton buttonClass="-mobile" />
                        </div>
                        :
                        <Row style={{ width: '100%', margin: 'auto' }}>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <PointPlusButton buttonClass="-mobile" />
                            </Col>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <AllowanceButton buttonClass="-mobile" />
                            </Col>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <ZeroPointButton buttonClass="-mobile" />
                            </Col>
                        </Row>
                    }
                    <Accordion className="accordion" defaultActiveKey="0">
                        <Card className="accordion-card">
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ expanded: !this.state.expanded })}>
                                <h2 className="accordion-h2" style={windowWidth < 500 ? { fontSize: '1.25rem' } : { fontSize: '2rem' }}><span><Image className="accordion-expand" src={this.state.expanded ? AccMinus : AccPlus} alt="Accordion Expand Signs" /></span>How to Calculate Weight Watchers Smart Points</h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                                    <div className='page-content-mobile'>
                                        <div style={{ textAlign: 'center' }}>
                                            {/* <Image src={LongEq} alt="Smart Point Long Hand Equation" style={{ width: '55rem', maxWidth: '100%', marginLeft: '1rem' }}></Image> */}
                                            {/* <p style={{ fontWeight: '500', marginTop: '-2rem' }}>OR</p> */}
                                            <Image src={ShortEq} alt="Smart Point Short Hand Equation" style={{ width: '35rem', maxWidth: '100%', margin: '2rem auto 3rem auto' }} />
                                        </div>
                                        <div className={"art-content" + windowType} style={{ marginTop: '1rem' }}>
                                            <p style={{ marginTop: '-1rem' }}>This is the equation that we use to calculate the SmartPoint™ value of your food or drink items (long-hand on top, short-hand on bottom).  I will be using the short-hand variation in the following excerpt.</p>
                                            <p>So, that's great and all but how is it even possible to know this is the exact equation, and then be able to solve for 4 unknown variables (those seemingly random multipliers for each food nutrient)?  At least this is what I was thinking when I first started investigating the science and math behind these smart point calculations.  It was nice to benefit from someone else's work, but it bothered me I wasn't certain it was accurate, so I decided to reverse engineer it.</p>
                                            <p style={{ fontStyle: 'italic' }}>This is how I did it.</p>
                                            <p>If you're starting from scratch, there's not much information to work with.  But you do know that:</p>
                                            <ol style={{ margin: 0 }}>
                                                <li>Calories, saturated fat, sugar, and protein are somehow combined to represent a food or drink item in the form of a number.</li>
                                                <li>Saturated fat and sugar increase this number, but protein decreases it.</li>
                                                <li>All foods and drinks can be represented in the form of this integer, which is generally less than 10 points per food or drink item.</li>
                                                <li>This integer is rounded to the nearest whole number.</li>
                                            </ol>
                                            <p style={{ fontStyle: 'italic' }}>So, given this set of data, how can we convert it into the equation we see above?</p>
                                            <p>Well, we can start with what we know. The first two data points tell us that the SmartPoint (SP) value is calculated using calories (C), saturated fat (F), sugar (S), and protein (P). Saturated fat and sugar add to this number while protein subtracts from this number. We can infer from this information the following:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={SimpleEq} alt="Simplest Form of Smart Point Equation" style={{ width: '10rem' }} />
                                            </div>
                                            <p>Next, we can use our third data point to further develop our equation. We know that the SmartPoint integer is some magnitude less than the raw addition and subtraction of the nutrients in a food. To factor this in, we need to choose one of the two following methods:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={Option1} alt="Multiplied Smart Point Calculation" style={{ width: '20rem', margin: 'auto' }} />
                                                <p style={{ fontWeight: '500', marginTop: '1.5rem' }}>OR</p>
                                                <Image src={Option2} alt="Divided Smart Point Calculation" style={{ width: '20rem', margin: 'auto' }} />
                                            </div>
                                            <p>Both are completely valid, but I'm going to stick with the top one since that's the one we use and the one I solved. Finally, we need to account for the variance of rounding, but to simplify things I will wait and demonstrate how that's done later on.</p>
                                            <p>Ok great, we've derived an equation for the SmartPoint, but we have a new puzzle in front of us, how are we going to solve for 4 unknown variables? I suppose it's theoretically possible to guess and check? Although that might take a while, even if you've developed efficient programmatic functions to do the heavy lifting.</p>
                                            <p>The route I took was to cut down on the number of unknown variables we need to solve because 4 is too many. The easiest way to do this is to find a food or drink that contains sugar, but no fat or protein. This will reduce the unknown variables to 2 because fat and protein will be 0 regardless of their multipliers.</p>
                                            <p>The food or drink type that came to me first was sugary drinks! Not only would the right one fit the criteria above, but we need something with total calories that come from sugar alone. For example, if we chose 12oz of orange juice, its total calories stem from proteins and other nutrients. Thus, as our ounces of orange juice increase, so too would the skew of our data.</p>
                                            <p>Due to this, I chose a can of regular <a rel="noreferrer" target="_blank" href="https://us.coca-cola.com/products/coca-cola/original">Coca Cola</a>. One can of coke contains 240 calories, 75mg of sodium, and 65g of sugar. Since sodium does not contain calories per se, this fits all of the criteria we are looking for. Now we can ask our myWW app what the SmartPoint value of a can of coke is, and temporarily simplify our equation.</p>
                                            <p>FROM:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={FullCSEq} alt="Full Calories and Sugar Smart Point Equation" style={{ width: '20rem', margin: 'auto' }} />
                                            </div>
                                            <p>TO:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={SimpleCSEq} alt="Simplified Calories and Sugar Smart Point Equation" style={{ width: '13.5rem', margin: 'auto' }} />
                                            </div>
                                            <p>Although WW tells us the SmartPoint value is 15, we know that number is rounded. So let's account for that in our simplified equation:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={RangeEq} alt="Simplified Sugar and Calorie Equation With Range" style={{ width: '17.5rem', margin: 'auto' }} />
                                            </div>
                                            <p>This means that the SmartPoint value is at least 14.5, but less than 15.5. Mathematically, it would look like this:
                                                <span><Image src={RangeExample} alt="Calories and Sugar Range Example" style={{ width: '11rem', marginTop: '-.25rem' }} /></span>
                                            </p>
                                            <p>I don't think it's necessary to go through each line of the code I wrote to represent this, however, I'll briefly explain what it does (if you're interested in it though, you can find it on my <a rel="noreferrer" target="_blank" href="https://github.com/gvanorden/ww-smart-point-calculations">GitHub</a>). Essentially, what we need to do is find all possible solutions for <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span>, then take the average of those values.</p>
                                            <p>For example, if I wanted to run through all solutions to the hundredth percentile, the values for <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span> would look something like this:</p>
                                            <Table className="sp-table" style={{ width: '20rem', margin: 'auto' }} size="sm">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.01</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.03</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.99 (all the way to .99)</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table className="sp-table" style={{ width: '20rem', margin: '1rem auto 0 auto' }} size="sm">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.01</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.02</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.03</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.99 (all the way to .99)</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>And so on (although in my calculations I went a bit deeper, incrementing to the thousandth percentile). The goal is to iterate through each possibility, incrementing each value by a hundredth, until all possible solutions for <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span> are calculated for a smart point value greater than or equal to 14.5 but less than 15.5.</p>
                                            <p>The results for this example calculation are:</p>
                                            <Table className="sp-table" style={{ width: '20rem', margin: 'auto' }} size="sm">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.19</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.01</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.20</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.15</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.02</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.16</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.03</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.12</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.04</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.08</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.04</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.09</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.05</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.04</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.05</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.05</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td>0.06</td>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td>0.01</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>Then, the arithmetic mean of those results: <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> = 0.032 and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span> = 0.11 </p>
                                            <p>Close, but the original equation states <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> = .0305 and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span> = .12, so where did we go wrong? Should we go back to the drawing board?</p>
                                            <p>Well, not quite yet. As I've mentioned before, because smart points are rounded, we are going to be dealing with averages, which means we need a sufficient sample size to cut through the variance and develop a multiplier closer to a foods actual SP number. For instance, conducting these calculations on the arithmetic mean of 10,000 cans will bring us much closer to the true SmartPoint value. This way we don't gyp anyone out of their points!</p>
                                            <p>Because I wanted to do this without referencing any external equations, I had to scan a few more cans of coke to see how each one influenced the overall smart point value. I ended up scanning 75 cans in the app, so they're probably worried about me, but it got the job done. What I was looking for was a repeatable pattern in the results, and I found one. The pattern looked like this:</p>
                                            <Table className="sp-table" style={{ width: 'auto', margin: 'auto' }} size="sm">
                                                <tbody>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>8</td>
                                                        <td>9</td>
                                                        <td>8</td>
                                                        <td>8</td>
                                                        <td>9</td>
                                                        <td>8</td>
                                                        <td>8</td>
                                                        <td>9</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p style={{ marginTop: '1rem' }}>What this means is that the 8th can of coke costs 16 smarts points, the next 8th can costs 16 points, but the following 9th can costs 16 points, then it repeats. I applied this pattern to represent what 100, 200, and eventually 10,000 cans of coke would cost in SmartPoints, ran it through the functions I built, and took the mean of those results. This is what that calculation produced:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.25rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td> = 0.030500000000000003</td>
                                                    </tr>
                                                    <tr style={{ fontSize: '1.25rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td> = 0.11999999999999998</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>And if we simplified it:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.25rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>c</td>
                                                        <td> = 0.0305</td>
                                                    </tr>
                                                    <tr style={{ fontSize: '1.25rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>s</td>
                                                        <td> = 0.12</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>Boom, the exact values in the accepted equation for calories and sugar. Now that we have successfully solved for 2 of the 4 unknown variables in the original equation, we can add those to the one we built:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={UpdatedEqCS} alt="Smart Point Equation Solved For Calories And Sugar" style={{ width: '20rem', margin: 'auto' }} />
                                            </div>
                                            <p>That was the tough part or at least the programmatically intensive part. From here on, we can apply the same concepts we used to solve for <span style={{ fontStyle: 'italic', color: 'red' }}>c</span> and <span style={{ fontStyle: 'italic', color: 'red' }}>s</span> to acquire <span style={{ fontStyle: 'italic', color: 'red' }}>f</span> and <span style={{ fontStyle: 'italic', color: 'red' }}>p</span>, our multipliers for saturated fat and protein.</p>
                                            <p>So, I picked a food that contains protein, but no saturated fat - the <a rel="noreferrer" target="_blank" href="https://idahopotato.com/nutritional-facts">Idaho Potato</a> - then manipulated our current equation using simple algebra to solve for little <span style={{ fontStyle: 'italic', color: 'red' }}>p</span>.</p>
                                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                <p style={{ fontWeight: 'bold' }}>Idaho Potato</p>
                                            </div>
                                            <Table className="sp-table" style={{ width: 'auto', margin: 'auto' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Calories</td>
                                                        <td style={{ textAlign: 'center' }}>110</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Saturated Fat</td>
                                                        <td style={{ textAlign: 'center' }}>0</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Sugar</td>
                                                        <td style={{ textAlign: 'center' }}>1</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Protein</td>
                                                        <td style={{ textAlign: 'center' }}>3</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>SmartPoints</td>
                                                        <td style={{ textAlign: 'center' }}>3</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={Potato1} alt="Solve for Smart Point Protein Multiplier Equation 1" style={{ width: '17.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={Potato2} alt="Solve for Smart Point Protein Multiplier Equation 2" style={{ width: '18.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={PotatoSolution} alt="Solve for Smart Point Protein Multiplier Solution 1" style={{ width: '6.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <p>Pretty far off from our .098 multiplier in the original equation. But let's not forget about deducing a number closer to the true SmartPoint value through increasing the sample size. So I expanded the calculation to 10,000 Idaho potatoes and once again took the average of those results:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.1rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>p</td>
                                                        <td> = 0.0980064999999842</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>And if we simplified it:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.1rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>p</td>
                                                        <td> = 0.098</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>Again, the exact value that's used in the original equation. Now that we've successfully solved for the protein multiplier, let's add it to ours:</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={ProteinSolved} alt="Smart Point Equation Solved for Protein" style={{ width: '20rem', margin: 'auto' }} />
                                            </div>
                                            <p>Ok, the final piece to this puzzle is to solve for <span style={{ fontStyle: 'italic', color: 'red' }}>f</span>, our saturated fat multiplier. No need to reiterate the process, I chose a <a rel="noreferrer" target="_blank" href="https://www.nestleprofessional.us/hot-pockets/hot-pockets-pepperoni-pizza-stuffed-sandwich-individual-wrap-12-x-8-ounces">Hot Pocket</a> for this because it was the first food I thought of.</p>
                                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                <p style={{ fontWeight: 'bold' }}>Hot Pocket</p>
                                            </div>
                                            <Table className="sp-table" style={{ width: 'auto', margin: 'auto' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Calories</td>
                                                        <td style={{ textAlign: 'center' }}>600</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Saturated Fat</td>
                                                        <td style={{ textAlign: 'center' }}>12</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Sugar</td>
                                                        <td style={{ textAlign: 'center' }}>5</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Protein</td>
                                                        <td style={{ textAlign: 'center' }}>19</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>SmartPoints</td>
                                                        <td style={{ textAlign: 'center' }}>20</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={HotPocket1} alt="Solve for Smart Point Saturated Fat Multiplier Equation 1" style={{ width: '18.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={HotPocket2} alt="Solve for Smart Point Saturated Fat Multiplier Equation 2" style={{ width: '19.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={HotPocketSolution} alt="Solve for Smart Point Saturated Fat Multiplier Solution" style={{ width: '5.5rem', marginTop: '2rem' }} />
                                            </div>
                                            <p>Close, but no cigar.  For the last time, we'll increase our sample size, take the arithmetic mean of 10,000 hot pockets, and the result is:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.1rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>f</td>
                                                        <td> = 0.2750094974423127</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>And if we simplified it:</p>
                                            <Table className="sp-table" style={{ width: 'auto', marginLeft: '2rem' }} size="sm">
                                                <tbody>
                                                    <tr style={{ fontSize: '1.1rem' }}>
                                                        <td style={{ fontStyle: 'italic', color: 'red' }}>f</td>
                                                        <td> = 0.275</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p>Alas, we have officially reverse-engineered the SmartPoint value equation and can confirm with certainty that it's accurate. To wrap things up, let's add the final value for our saturated fat multiplier and complete the equation.</p>
                                            <div style={{ textAlign: 'center' }}>
                                                <Image src={FinalEquation} alt="Weight Watchers Points Calculator Equation" style={{ width: '20rem', margin: '1rem auto 2rem auto' }} />
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                <Footer />
                <Helmet>
                    <title>Weight Watchers Calculator | WW Points Calculator</title>
                    <meta name='description' content="Find out the point value of foods & drinks using this WW calculator. This will help you follow the weight watchers program & get healthy" />
                    <meta name="keywords" content="weight watchers smart points calculator, weight watchers points calculator, smart points calculator, weight watchers smart points formula, ww points calculator, ww calculator, free weight watchers calculator, weight watchers calculator app" />
                </Helmet>
            </React.Fragment >
        );
    }
}

export default SmartPointCalculatorMobile;