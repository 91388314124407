import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

class ZeroPointButton extends Component {
    state = {}

    setButtonClass() {
        let windowWidth = this.props.windowWidth

        if (windowWidth > 1024) {
            return "zp-button-fixed"
        }
        else if (windowWidth > 600) {
            return "zp-button-mobile-wide"
        }
        else {
            return "zp-button-mobile"
        }
    }

    render() {
        return (
            <Button variant="dark" className={"zp-button" + this.props.buttonClass} onClick={() => window.location.href = "/weight-watchers-zero-point-foods"}>Zero Point Validator</Button>
        );
    }
}

export default ZeroPointButton;