import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import AllowanceCalculatorDesktop from './desktop/allowance-calculator-desktop';
import AllowanceCalculatorMobile from './mobile/allowance-calculator-mobile';



class AllowanceCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                {isMobile ?
                    <AllowanceCalculatorMobile />
                    :
                    <AllowanceCalculatorDesktop />
                }
            </React.Fragment >
        );
    }
}

export default AllowanceCalculator;