import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import MainMenu from '../../images/main-menu.png';
import DailyFoodTracker from '../../images/daily-food-tracker.png';
import DailyWeightLogger from '../../images/daily-weight-logger.png';
import SmartpointCalculator from '../../images/smartpoint-calculator.png';
import FoodPointLists from '../../images/food-point-lists.png'
import ActivityPointTracker from '../../images/activity-points-tracker.png'
import PersonalPointList from '../../images/personal-points-lists.png'
import GreenList from '../../images/green-list.png';
import BlueList from '../../images/blue-list.png';
import PurpleList from '../../images/purple-list.png';

const appSliderTextx = [["Scan food and drink barcodes!", "Track daily & weekly point usage!", "Calculate SmartPoints of foods & drinks!"], ["Monitor daily weight changes!", "Create your own food list for easy tracking!", "Quickly navigate and filter zero point foods!"]]
const appSliderPhotos = [MainMenu, DailyFoodTracker, SmartpointCalculator, DailyWeightLogger, FoodPointLists, ActivityPointTracker, PersonalPointList, GreenList, BlueList, PurpleList]

class BannerMobileLarge extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { windowWidth } = this.props

        return (
            <div className="welcome-area" style={{ overflow: 'hidden' }}>
                <div style={{ height: '100px' }}>
                    <div className="welcome-heading-mobile-large">
                        <h2 className="w-h1-pink" style={windowWidth < 1024 ? { fontSize: '1.75rem' } : { fontSize: '2.5rem' }}>
                            {/* DOWNLOAD THE FREE APP! */}
                            DOWNLOAD OUR APP!
                        </h2>
                        <h3 className="bounce" style={{ overflow: 'hidden' }}>WatchPoint</h3>
                        <Carousel controls={false} indicators={false} style={{ position: 'absolute' }}>
                            {appSliderTextx.map((text, i) => (
                                <Carousel.Item key={i} style={{ color: 'white', fontSize: '1.25rem' }}>
                                    <ul style={{ color: 'white', fontWeight: 500, paddingLeft: '1.5rem', fontSize: '1.25rem' }}>
                                        <li>{text[0]}</li>
                                        <li>{text[1]}</li>
                                        <li>{text[2]}</li>
                                    </ul>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <div className="welcome-app-button" style={{ float: 'left', marginTop: '6.5rem' }}>
                            <a id="download-now" rel="noreferrer" target="_blank" href="https://apps.apple.com/us/app/iwatchpoints/id6443529573" className="bkgd-color-change">Download For iPhone!</a>
                            <a id="download-now" rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=io.ionic.watcherspoint&hl=en-US&ah=fwv4wR27Hpc81mA9YrDXhWh1DUo" className="bkgd-color-change" style={{ marginLeft: '1rem' }}>Download For Android!</a>
                        </div>
                    </div>
                </div>
                <div style={windowWidth < 1024 ? { position: 'absolute', top: '6.75rem', right: '10%', width: '8rem' } : { position: 'absolute', top: '6.75rem', right: '17.5%', width: '8rem' }}>
                    <Carousel style={{ background: 'linear-gradient(0deg, #4dabff 0%, #62ddff 100%)' }} controls={false} indicators={false} interval={2000}>
                        {appSliderPhotos.map((photo, i) => (
                            <Carousel.Item key={i}>
                                <Image style={{ width: '100%', borderRadius: '.25rem' }} src={photo} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div >
        )
    }
}

export default BannerMobileLarge;