import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import SmartPointCalculatorDesktop from './desktop/smart-point-calculator-desktop';
import SmartPointCalculatorMobile from './mobile/smart-point-calculator-mobile';

class SmartPointCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <React.Fragment>
                {isMobile ?
                    <SmartPointCalculatorMobile />
                    :
                    <SmartPointCalculatorDesktop />
                }
            </React.Fragment >
        );
    }
}

export default SmartPointCalculator;