import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

class SmartPointButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    setButtonClass() {
        let windowWidth = this.props.windowWidth

        if (windowWidth > 1024) {
            return "sp-button-fixed"
        }
        else if (windowWidth > 600) {
            return "sp-button-mobile-wide"
        }
        else {
            return "sp-button-mobile"
        }
    }


    render() {
        return (
            <Button variant="danger" className={"sp-button" + this.props.buttonClass} onClick={() => window.location.href = "/weight-watchers-smart-points-calculator"}>Smart Point Calculator</Button>
        );
    }
}

export default SmartPointButton;