import React, { Component } from 'react';
import { isMobile, browserName } from 'react-device-detect';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import WpLogo from './images/wp-logo.png';
import Index from './components/index';
import SmartPointCalculator from './components/weight-watchers-smart-points-calculator';
import PointPlusCalculator from './components/points-plus-calculator';
import AllowanceCalculator from './components/allowance-calculator';
import ZeroPointFoodsCalculator from './components/weight-watchers-zero-point-foods';
// import FoodListCalculator from './components/calculators/sp-foodlist-calculator';
import AboutUs from './components/about-us';
import ContactUs from './components/contact-us';
import PrivacyPolicy from './components/privacy-policy-and-terms-of-service';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/animate.css';
import './styles/index.css';
import './styles/nav.css';
import './styles/calculator.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowType: "",
      windowWidth: window.outerWidth,
      browser: null
    };

    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    let windowWidth = window.outerWidth
    if (windowWidth === 0) { windowWidth = window.innerWidth }
    if (windowWidth === 0) { windowWidth = window.screen.width }
    if (windowWidth === 0) { windowWidth = 1367 }

    if (windowWidth < 1200) {
      this.setState({ windowType: "-mobile" });
    }

    this.setState({ windowWidth: windowWidth, browser: browserName });
  }

  handleResize() {
    setTimeout(() => {
      let windowWidth = window.outerWidth
      if (windowWidth === 0) { windowWidth = window.innerWidth }
      if (windowWidth === 0) { windowWidth = window.screen.width }
      if (windowWidth === 0) { windowWidth = 1367 }

      if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
      else { this.setState({ windowType: "", windowWidth: windowWidth }); }
    }, 50);
  }

  setCollapseClass() {
    const { browser } = this.state
    let innerWidth = window.innerWidth
    let outerWidth = window.outerWidth

    if (isMobile) {
      if ((innerWidth > 0 && innerWidth < 600) || (outerWidth > 0 && outerWidth < 600)) {
        return "wp-collapse-xs"
      }
      else if ((innerWidth > 0 && innerWidth < 1200) || (outerWidth > 0 && outerWidth < 1200)) {
        return "wp-collapse-sm"
      } else {
        return
      }
    } else if (!browser) {
      return
    }
    else if ((innerWidth > 0 && innerWidth < 1200) || (outerWidth > 0 && outerWidth < 1200)) {
      return "wp-collapse-sm"
    } else {
      return
    }
  }

  setNavAppButtonStyles() {
    let windowWidth = window.outerWidth
    if (windowWidth === 0) { windowWidth = window.innerWidth }
    if (windowWidth === 0) { windowWidth = window.screen.width }
    if (windowWidth === 0) { windowWidth = 1367 }

    if (windowWidth > 0 && windowWidth < 600) { return { left: '6.5rem' } }
    else if (windowWidth > 0 && windowWidth > 1200 && windowWidth <= 1366) { return { right: '2rem' } }
    else { return { right: '5.5rem' } }
  }

  setLogoStyles() {
    let windowWidth = window.outerWidth
    if (windowWidth === 0) { windowWidth = window.innerWidth }
    if (windowWidth === 0) { windowWidth = window.screen.width }
    if (windowWidth === 0) { windowWidth = 1367 }

    if (windowWidth > 0 && windowWidth < 600) {
      return 'wp-logo-mobile'
    }
    else {
      return 'wp-logo'
    }
  }

  render() {
    const { windowType, windowWidth } = this.state

    return (
      <BrowserRouter>
        <Navbar className="wp-nav-bar animated" expand="xl" fixed="top">
          <Navbar.Brand>
            <img
              src={WpLogo}
              alt="Watchers Point Logo"
              className={this.setLogoStyles()}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-dark" style={windowWidth < 600 ? { position: 'absolute', top: '1.9rem', left: '16rem' } : null} />
          <Navbar.Collapse id="responsive-navbar-nav" className={this.setCollapseClass()}>
            <Nav.Link className={"wp-nav-link" + windowType} href="/">Home</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/weight-watchers-smart-points-calculator">Smart Points Calculator</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/points-plus-calculator">Point Plus Calculator</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/allowance-calculator">Allowance Calculator</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/weight-watchers-zero-point-foods">Zero Point Foods</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/about-us">About Us</Nav.Link>
            <Nav.Link className={"wp-nav-link" + windowType} href="/contact-us">Contact Us</Nav.Link>
          </Navbar.Collapse>
          {/* {isMobile ?
            <div style={{ position: 'absolute', top: '2.5rem', right: '6.5rem', fontSize: '.875rem' }}>
              <p style={{ color: '#ffffff' }}>Other Calculators Here ⇨</p>
            </div>
            : null} */}
          {/* <div className={"nav-app-button" + windowType} style={this.setNavAppButtonStyles()}>
            <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=io.thewatcherspoint.trackers">Get The Free App</a>
          </div> */}
        </Navbar>
        <Switch>
          <Route exact path='/' component={Index} />
          <Route exact path='/weight-watchers-smart-points-calculator' component={SmartPointCalculator} />
          <Route exact path='/smart-point-calculator' component={SmartPointCalculator} />
          <Route exact path='/points-plus-calculator' component={PointPlusCalculator} />
          <Route exact path='/allowance-calculator' component={AllowanceCalculator} />
          <Route exact path='/weight-watchers-zero-point-foods' component={ZeroPointFoodsCalculator} />
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/privacy-policy-and-terms-of-service' component={PrivacyPolicy} />
        </Switch>
      </BrowserRouter >
    )
  }
}

export default App;