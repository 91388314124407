import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import PpCalculator from '../calculators/pp-calculator';
import PPEq1 from '../../images/pp-eq-1.png';
import PPEq2 from '../../images/pp-eq-2.png';
import PPEq3 from '../../images/pp-eq-3.png';
import PPEq4 from '../../images/pp-eq-4.png';
import PPEq5 from '../../images/pp-eq-5.png';
import PPSh1 from '../../images/pp-sh-1.png';
import PPSh2 from '../../images/pp-sh-2.png';
import PPSh3 from '../../images/pp-sh-3.png';
import PPSh4 from '../../images/pp-sh-4.png';
import PPSh5 from '../../images/pp-sh-5.png';
import PPSh6 from '../../images/pp-sh-6.png';
import PPSh7 from '../../images/pp-sh-7.png';
import AccPlus from '../../images/accordion-plus.png';
import AccMinus from '../../images/accordion-minus.png';
import SmartPointButton from '../buttons/smart-point-button';
import AllowanceButton from '../buttons/allowance-button';
import ZeroPointButton from '../buttons/zero-point-button';
import GoogleSquareAd from '../google-square-ad';
import BannerMobile from '../app-banners/banner-mobile';
import BannerMobileLarge from '../app-banners/banner-mobile-large';
import BannerDesktop from '../app-banners/banner-desktop';
import Footer from '../footer';

class PointsPlusCalculatorMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowType: "",
            windowWidth: window.outerWidth,
            ex0: false,
            ex1: true,
            ex2: false,
            bannerType: ""
        };

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        let windowWidth = window.outerWidth
        if (windowWidth === 0) { windowWidth = window.innerWidth }
        if (windowWidth === 0) { windowWidth = window.screen.width }
        if (windowWidth === 0) { windowWidth = 1367 }

        if (windowWidth < 1200) {
            this.setState({ windowType: "-mobile" });
        }

        this.setState({ windowWidth: windowWidth });

        if (windowWidth <= 620) { this.setState({ bannerType: 'mobile' }) }
        else if (windowWidth <= 1175) { this.setState({ bannerType: 'mobile-large' }) }
        else { this.setState({ bannerType: 'desktop' }) }
    }

    handleResize() {
        setTimeout(() => {
            let windowWidth = window.outerWidth
            if (windowWidth === 0) { windowWidth = window.innerWidth }
            if (windowWidth === 0) { windowWidth = window.screen.width }
            if (windowWidth === 0) { windowWidth = 1367 }

            if (windowWidth < 1200) { this.setState({ windowType: "-mobile", windowWidth: windowWidth }); }
            else { this.setState({ windowType: "", windowWidth: windowWidth }); }

            if (windowWidth <= 620) { this.setState({ bannerType: 'mobile' }) }
            else if (windowWidth <= 1175) { this.setState({ bannerType: 'mobile-large' }) }
            else { this.setState({ bannerType: 'desktop' }) }
        }, 50);
    }

    render() {
        const { windowType, windowWidth, bannerType } = this.state

        return (
            <React.Fragment>
                {bannerType === 'mobile' ? <BannerMobile windowWidth={windowWidth} /> : null}
                {bannerType === 'mobile-large' ? <BannerMobileLarge windowWidth={windowWidth} /> : null}
                {bannerType === 'desktop' ? <BannerDesktop windowWidth={windowWidth} /> : null}
                <div className={"container" + windowType}>
                    {windowWidth > 800 ? <div className="triangle-bkgd"></div> : null}
                    <GoogleSquareAd />
                    <PpCalculator windowWidth={this.state.windowWidth} />
                    {windowWidth < 500 ?
                        <div style={{ width: '90%', margin: 'auto' }}>
                            <SmartPointButton buttonClass="-mobile" />
                            <AllowanceButton buttonClass="-mobile" />
                            <ZeroPointButton buttonClass="-mobile" />
                        </div>
                        :
                        <Row style={{ width: '100%', margin: 'auto' }}>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <SmartPointButton buttonClass="-mobile" />
                            </Col>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <AllowanceButton buttonClass="-mobile" />
                            </Col>
                            <Col style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                                <ZeroPointButton buttonClass="-mobile" />
                            </Col>
                        </Row>
                    }
                    <Accordion className="accordion" defaultActiveKey="1">
                        <Card className="accordion-card">
                            <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ ex0: !this.state.ex0, ex1: false, ex2: false })}>
                                <h2 className="accordion-h2" style={windowWidth < 500 ? { fontSize: '1.25rem' } : { fontSize: '2rem' }}><span><Image className="accordion-expand" src={this.state.ex0 ? AccMinus : AccPlus} alt="Accordion Expand Signs" /></span>When was Weight Watchers Points Plus Introduced?</h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                                    <div className={"page-content" + windowType}>
                                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                                            <p>In 2010, Weight Watchers™ restructured their entire pre-existing points system and revealed their PointsPlus™ innovation. With PointsPlus, Weight Watchers introduced the concept of zero point foods with fruits and vegetables (non-starchy) costing 0 points.</p>
                                            <p>Although the WW points system and overall programs have been modified since 2010, the Points Plus system is still a valid recipe for a healthier lifestyle and will fuel better eating habits.</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="accordion-card">
                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ ex0: false, ex1: !this.state.ex1, ex2: false })}>
                                <h2 className="accordion-h2" style={windowWidth < 500 ? { fontSize: '1.25rem' } : { fontSize: '2rem' }}><span><Image className="accordion-expand" src={this.state.ex1 ? AccMinus : AccPlus} alt="Accordion Expand Signs" /></span>What is Weight Watchers Points Plus?</h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                                    <div className={"page-content" + windowType}>
                                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                                            <p>The WW™ points plus calculator uses protein, carbs, total fat (saturated and unsaturated), and fiber to determine the point value of a food or drink. Like SmartPoints, the Points Plus number is combined with two separate allowance metrics:</p>
                                            <p>1. Daily Point Allowance</p>
                                            <ul style={{ listStyleType: 'square' }}>
                                                <li>Between 26 and 79 Points Plus pts</li>
                                            </ul>
                                            <p>This is calculated using a person's gender, height, weight, age, and activity level. If you’re interested in your daily points allowance, you can visit our <a href="/allowance-calculator">allowance calculator</a> page which can quickly provide this value for you in addition to running through examples and an explanation of how it’s calculated.</p>
                                            <p>2. Weekly Point Allowance</p>
                                            <ul style={{ listStyleType: 'square' }}>
                                                <li>49 Points Plus pts</li>
                                            </ul>
                                            <p>This weekly point allowance is a static number that may be allocated freely to foods and drinks of your choice throughout the week.</p>
                                            <p>The Weight Watchers Points Plus program focuses on the nutritional aspect of food rather than a raw calorie count with saturated fat and sugar modifiers such as the new SmartPoint system. Its primary purpose is to promote the consumption of foods with higher protein and fiber content due to their innate nutritional content (these options typically include many fruits and vegetables) and their filling nature.</p>
                                            <p>By sticking to the recommended foods and your daily points allowance, not only will the participant reliable lose 1 to 2 pounds a week, but also transition themselves into healthier eating habits and a better overall lifestyle.</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="accordion-card">
                            <Accordion.Toggle as={Card.Header} eventKey="2" onClick={() => this.setState({ ex0: false, ex1: false, ex2: !this.state.ex2 })}>
                                <h2 className="accordion-h2" style={windowWidth < 500 ? { fontSize: '1.25rem' } : { fontSize: '2rem' }}><span><Image className="accordion-expand" src={this.state.ex2 ? AccMinus : AccPlus} alt="Accordion Expand Signs" /></span>How the Weight Watchers Points Plus Calculator Works</h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                                    <div className={"page-content" + windowType}>
                                        <div className={"art-content" + windowType} style={{ marginTop: '2rem' }}>
                                            <p>There are 2 possible methods to calculate the Points Plus value of a food or drink.  The equation we use in our calculator and a short-hand mental math option.  We’ll quickly run through both options using an avocado in our examples.</p>
                                            <p>These are the nutrition facts for an avoacado, which represent the values we'll be using:</p>
                                            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                                                <p style={{ fontWeight: 'bold' }}>Avocado</p>
                                            </div>
                                            <Table className="sp-table" style={{ width: '25%', margin: 'auto' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Protein</td>
                                                        <td style={{ textAlign: 'center' }}>4</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Carbs</td>
                                                        <td style={{ textAlign: 'center' }}>17</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Fat</td>
                                                        <td style={{ textAlign: 'center' }}>29</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ fontStyle: 'italic' }}>Fiber</td>
                                                        <td style={{ textAlign: 'center' }}>13</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <p style={{ marginTop: '4rem', fontSize: '24px' }}>1. Points Plus Calculator Method</p>
                                            <p>This is the well-known, mathematical equation to calculate the Weight Watchers Points Plus value of a food or drink:</p>
                                            <div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPEq1} alt="Point Plus Full Equation" style={{ width: '40rem', maxWidth: '102.5%' }} />
                                                </div>
                                            </div>
                                            <p>Now, lets plug the nutrition values into our equations:</p>
                                            <div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPEq2} alt="Point Plus Full Equation With Values" style={{ width: '40rem', maxWidth: '102.5%' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPEq3} alt="Point Plus Equation With Values Simplified" style={windowWidth < 500 ? { width: '14rem' } : { width: '25rem' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPEq4} alt="Point Plus Solution to Hundredth Decimal Place" style={windowWidth < 500 ? { width: '6.25rem' } : { width: '11.5rem' }} />
                                                </div>
                                            </div>
                                            <p>Then, if we round our result to the nearest whole number, we end up with:</p>
                                            <div style={{ marginTop: '1.5rem' }}>
                                                <Image src={PPEq5} alt="Point Plus Rounded Solution" style={windowWidth < 500 ? { width: '5.5rem' } : { width: '9.75rem' }} />
                                            </div>
                                            <p>As you can see, a single avocado is not quite worth 9 points even though that is the official and accepted Points Plus value from Weight Watchers.  Although we lost a few tenths of a point in this example, rounded points can also work in the participant's favor.</p>
                                            <p>Thus, unless you eat the same exact foods that continually round your point values up, these will eventually even out in the long run. We can also take advantage of these relative values in order to use a short-hand, mental-math approach that can assist you in a pinch if you don’t have time or access to use a calculator.</p>
                                            <p style={{ marginTop: '4rem', fontSize: '28px' }}>2. Points Plus Estimated (Short-Hand) Method</p>
                                            <p>This is the simplified short-hand equation we'll use to estimate the Points Plus value of an avocado:</p>
                                            <div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh1} alt="Point Plus ShortHand Mental Math Equation" style={{ width: '35rem', maxWidth: '100%' }} />
                                                </div>
                                            </div>
                                            <p>Now, we if once again input our nutritional values for an avocado and simplify our equation a bit so we can do some mental math:</p>
                                            <div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh2} alt="Point Plus ShortHand Mental Math Equation With Values" style={{ width: '27.5rem', maxWidth: '100%' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh3} alt="Point Plus ShortHand Mental Math Equation Simplified" style={{ width: '25rem', maxWidth: '100%' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh4} alt="Point Plus ShortHand Mental Math Equation Simplified Further" style={{ width: '25rem', maxWidth: '100%' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh5} alt="Point Plus ShortHand Mental Math Equation Solved" style={windowWidth < 500 ? { width: '12.5rem', marginLeft: '.5rem' } : { width: '16rem', marginLeft: '.75rem' }} />
                                                </div>
                                                <div style={{ marginTop: '1.5rem' }}>
                                                    <Image src={PPSh6} alt="Point Plus ShortHand Mental Math Solution Unsimplified" style={windowWidth < 500 ? { width: '8.25rem', marginTop: '.5rem', marginLeft: '.5rem' } : { width: '10.5rem', marginTop: '.5rem', marginLeft: '.75rem' }} />
                                                </div>
                                            </div>
                                            <p>Finally, if we simplify our mental-math result to the nearest whole number:</p>
                                            <div style={{ marginTop: '1.5rem' }}>
                                                <Image src={PPSh7} alt="Point Plus ShortHand Mental Math Solution Simplified" style={windowWidth < 500 ? { width: '8.75rem', marginTop: '.5rem' } : { width: '11rem', marginTop: '.5rem', marginLeft: '.25rem' }} />
                                            </div>
                                            <p>Nice, we've arrived at the same result as our calculator and now have a means of determining Points Plus values anywhere and anytime!</p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                <Footer />
                <Helmet>
                    <title>Weight Watchers Point Plus Calculator | Watchers Point</title>
                    <meta name='description' content="Use this Weight watchers point plus calculator to find out the value of any food accurately. All you need to enter is the Fat, Carbs, Protein and fiber quantity." />
                    <meta name="keywords" content="weight watchers points plus calculator, points plus calculator, ww points plus calculator, weight watchers point plus calculator, points plus calculator manual, old weight watchers points calculator" />
                </Helmet>
            </React.Fragment>
        );
    }
}

export default PointsPlusCalculatorMobile;