import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import MainMenu from '../../images/main-menu.png';
import DailyFoodTracker from '../../images/daily-food-tracker.png';
import DailyWeightLogger from '../../images/daily-weight-logger.png';
import SmartpointCalculator from '../../images/smartpoint-calculator.png';
import FoodPointLists from '../../images/food-point-lists.png'
import ActivityPointTracker from '../../images/activity-points-tracker.png'
import PersonalPointList from '../../images/personal-points-lists.png'
import GreenList from '../../images/green-list.png';
import BlueList from '../../images/blue-list.png';
import PurpleList from '../../images/purple-list.png';

const appSliderPhotos = [MainMenu, DailyFoodTracker, SmartpointCalculator, DailyWeightLogger, FoodPointLists, ActivityPointTracker, PersonalPointList, GreenList, BlueList, PurpleList]

class BannerDesktop extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // const { windowWidth } = this.props

        return (
            <div className="welcome-area" style={{ overflow: 'hidden' }}>
                <div style={{ height: '100px' }}>
                    <div className="welcome-heading">
                        <h2 className="w-h1-pink" style={{ fontSize: '2rem' }}>
                            {/* DOWNLOAD THE FREE iPHONE APP! */}
                            DOWNLOAD OUR APP - 7 DAY FREE TRIAL!
                        </h2>
                        <h3 className="bounce">WatchPoint</h3>
                        <Row>
                            <Col>
                                <ul style={{ float: 'left', color: 'white', fontWeight: 500, paddingLeft: '1.5rem', fontSize: '1.25rem' }}>
                                    <li>Scan food barcodes</li>
                                    <li>Track daily & weekly point usage</li>
                                    <li>Calculate SmartPoint values</li>
                                </ul>
                                <ul style={{ float: 'left', color: 'white', fontWeight: 500, fontSize: '1.25rem' }}>
                                    <li>Monitor daily weight changes</li>
                                    <li>Create your own food list for easy tracking</li>
                                    <li>Quickly navigate and filter zero point foods</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="welcome-app-button">
                                    <a id="download-now" rel="noreferrer" target="_blank" href="https://apps.apple.com/us/app/iwatchpoints/id6443529573" className="bkgd-color-change">Download For iPhone!</a>
                                    <a id="download-now" rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=io.ionic.watcherspoint&hl=en-US&ah=fwv4wR27Hpc81mA9YrDXhWh1DUo" className="bkgd-color-change" style={{ marginLeft: '1rem' }}>Download For Android!</a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: '6.75rem', right: '12.5%', width: '8rem' }}>
                    <Carousel style={{ background: 'linear-gradient(0deg, #4dabff 0%, #62ddff 100%)' }} controls={false} indicators={false} interval={2000}>
                        {appSliderPhotos.map((photo, i) => (
                            <Carousel.Item key={i}>
                                <Image style={{ width: '100%', borderRadius: '.25rem' }} src={photo} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default BannerDesktop;